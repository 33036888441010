<template>
  <div class="flex flex-col col-span-12">
    <label class="col-span-12 c-faded-label">{{ t("purchase.product.dimensions.label") }}</label>
    <FloatLabelInputNumber
      id="product-dimension-height"
      dataTestId="product-dimension-height"
      :label="t('purchase.product.dimensions.height')"
      buttonLabel="cm"
      :isFloatingLabel="false"
      :value="props.dimension?.height"
      :disabled="true"
    />
    <FloatLabelInputNumber
      id="product-dimension-width"
      dataTestId="product-dimension-width"
      :label="t('purchase.product.dimensions.width')"
      buttonLabel="cm"
      :isFloatingLabel="false"
      :value="props.dimension?.width"
      :disabled="true"
    />

    <FloatLabelInputNumber
      id="product-dimension-length"
      dataTestId="product-dimension-length"
      :label="t('purchase.product.dimensions.length')"
      buttonLabel="cm"
      :isFloatingLabel="false"
      :value="props.dimension?.length"
      :disabled="true"
    />

    <FloatLabelInputNumber
      id="product-weight"
      dataTestId="product-weight"
      :label="t('purchase.product.dimensions.weight')"
      buttonLabel="kg"
      :isFloatingLabel="false"
      :value="props.weight?.value"
      :disabled="true"
    />
  </div>
</template>

<script setup lang="ts">
import { Dimension } from "@/models/search/product/Dimension";
import { Weight } from "@/models/search/product/Weight";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  dimension?: Dimension;
  weight?: Weight;
}>();
</script>
