<template>
  <ContextMenu ref="cm" :model="menuModel" data-testid="purchase-context-menu" />
  <div class="card">
    <ColumnOptionsMenu :label="t('purchase.purchase-header')" :itemsList="items"></ColumnOptionsMenu>
    <DataTable
      ref="purchaseOrderList"
      :value="purchaseOrders"
      dataKey="id"
      :autoLayout="true"
      v-model:contextMenuSelection="selectedPurchaseOrder"
      responsiveLayout="scroll"
      selectionMode="single"
      @row-select="onRowSelected"
      @row-dblclick="onRowDblClicked"
      v-model:selection="selectedPurchaseOrder"
      :sortOrder="sortOrder"
      :sortField="sortField"
      class="c-datatable"
      :loading="loading"
      :stripedRows="true"
      :paginator="true"
      :rows="pageSize"
      :lazy="true"
      :totalRecords="totalHits"
      :rowsPerPageOptions="[50, 100]"
      :currentPageReportTemplate="
        t('common.current-page-template', { first: '{first}', last: '{last}', totalRecords: '{totalRecords}' })
      "
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      @sort="onSort"
      @page="onPage"
      tabindex="0"
      data-testid="purchase-order-search-result"
      :rowClass="addGlobalSearchClass"
      scrollable
      scrollHeight="75vh"
      @rowContextmenu="onRowContextMenu"
      :resizableColumns="true"
      columnResizeMode="fit"
      removableSort
      @column-resize-end="onColumnResizeEnd"
      :reorderable-columns="true"
      @column-reorder="onColumnReorder"
      :key="renderKey as unknown as number"
    >
      <Column
        v-for="(col, index) of selectedColumnsComputed as unknown as DataTableColumn[]"
        :field="col.field"
        :header="col.header"
        :key="col.field + '_' + index"
        :class="col.class"
        :sortable="col.sortable"
        :pt="{
          headerCell: {
            id: col.field,
          },
        }"
        :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
      >
        <template #body="{ data, field, index }">
          <template v-if="col.field === PurchaseOrderListColumns.Registered">
            <div class="flex justify-between items-center">
              <span>{{ d(data.registered, "long") }}</span>
            </div>
          </template>
          <template v-else-if="col.field === PurchaseOrderListColumns.RegisteredByEmployeeInitials">
            <span>
              {{ data.registeredByEmployeeInitials }}
            </span>
          </template>
          <template v-else-if="col.field === PurchaseOrderListColumns.PurchaseOrderNumber">
            <span class="c-purchase-order-number-text" @click="onPurchaseNumberClicked(data)">{{
              data.purchaseOrderNumber
            }}</span>
          </template>
          <template v-else-if="col.field === PurchaseOrderListColumns.SupplierNumber">
            <span>{{ data.supplier.supplierNumber }}</span>
          </template>
          <template v-else-if="col.field === PurchaseOrderListColumns.SupplierName">
            <span>{{ data.supplier.name }}</span>
          </template>
          <template v-else-if="col.field === PurchaseOrderListColumns.PurchaseOrderReference">
            <span>{{ data.purchaseOrderReference }}</span>
          </template>
          <template v-else-if="col.field === PurchaseOrderListColumns.FreightMethodname">
            <span>{{ data.freightMethod.name }}</span>
          </template>
          <template v-else-if="col.field === PurchaseOrderListColumns.TotalItems">
            {{ n(data.sumTotalLines, "decimal") }}
          </template>
          <template v-else-if="col.field === PurchaseOrderListColumns.QuantityOfPurchaseOrderLines">
            {{ n(data.quantityOfPurchaseOrderLines) }}
          </template>
          <template v-else-if="col.field === PurchaseOrderListColumns.PurchaseOrderStatus">
            {{ t(`purchase.status.${data.purchaseOrderStatus.toLowerCase()}`) }}
          </template>
          <template v-else-if="col.field === PurchaseOrderListColumns.PDF">
            <img
              :data-testid="'btn-show-pdf-' + index"
              :src="pdfIcon"
              @click="$emit('downloadPdfBtnClicked', data.id)"
            />
          </template>
          <template v-else>
            {{ data[field] }}
          </template>
        </template>
      </Column>

      <template #empty v-if="!loading">
        <span>{{ t("purchase.search.total-hits", 0) }}</span>
      </template>
    </DataTable>
  </div>
  <ColumnChooser
    v-model:visibleDialog="visible"
    v-model:selectedColumns="selectedColumnsComputed"
    :columns="filteredColumns"
    :label="t('common.reset')"
    :onSelectAllChange="onSelectAllChange"
    :selectAll="selectAll"
    @resetColumns="resetColumns"
    @columnSelected="onColumnSelected"
    @columnUnselected="onColumnUnselected"
  />
  <PrintDialog v-model:visibleDialog="showPrintModal" :purchase-order="selectedPurchaseOrder" v-if="showPrintModal" />
</template>

<script setup lang="ts">
import { SearchPurchaseOrder } from "@/models/search/purchase-order/SearchPurchaseOrder";
import {
  DataTablePageEvent,
  DataTableRowClickEvent,
  DataTableRowDoubleClickEvent,
  DataTableRowSelectEvent,
  DataTableSelectAllChangeEvent,
  DataTableSortEvent,
} from "primevue/datatable";
import pdfIcon from "@/assets/PDF_file_icon.svg";
import { computed, nextTick, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { NIL as emptyUuid } from "uuid";
import { useRouter, useRoute } from "vue-router";
import { useCumulusToast } from "@cumulus/toast";
import { useToast } from "primevue/usetoast";
import PrintDialog from "./PrintDialog.vue";
import { PurchaseOrderListColumns } from "@/models/purchase-order/search/PurchaseOrderListColumns";
import { useAuth } from "@cumulus/event-bus";

import { ColumnChooser, useTablePreferences, ColumnOptionsMenu } from "@cumulus/components";
import type { DataTableColumn } from "@cumulus/components";

const { t, d, n } = useI18n();
const router = useRouter();
const route = useRoute();
const toast = useCumulusToast(useToast());
const showPrintModal = ref(false);
const visible = ref(false);
const menuModel = ref([{ label: t("common.print"), icon: "pi pi-fw pi-print", command: () => togglePrintModal() }]);
const cm = ref();
const togglePrintModal = () => {
  showPrintModal.value = !showPrintModal.value;
};

const purchaseOrderSearchColumns = [
  { field: "registered", header: t("purchase.search.registered"), class: "", sortable: true },
  {
    field: "registeredByEmployeeInitials",
    header: t("purchase.search.registered-by"),
    sortable: true,
  },
  {
    field: "purchaseOrderNumber",
    header: t("purchase.search.purchase-number"),
    sortable: true,
  },
  {
    field: "supplier.supplierNumber",
    header: t("purchase.search.supplier-number"),
    class: "text-center",
    sortable: true,
  },
  { field: "supplier.name", header: t("purchase.search.supplier-name"), class: "", sortable: true },
  {
    field: "purchaseOrderReference",
    header: t("purchase.search.purchase-reference"),
    sortable: true,
  },
  {
    field: "freightMethod.name",
    header: t("purchase.search.freight-method"),
    sortable: true,
  },
  { field: "totalItems", header: t("purchase.search.total-items-ex-vat"), sortable: true },
  {
    field: "quantityOfPurchaseOrderLines",
    header: t("purchase.search.quantity-order-lines"),
    sortable: true,
  },
  {
    field: "purchaseOrderStatus",
    header: t("purchase.search.purchase-status"),
    sortable: true,
    class: "text-center",
  },
  {
    field: "pdf",
    header: "PDF",
    sortable: false,
    class: "c-pdf-icon w-4 p-4",
  },
];

const props = defineProps<{
  purchaseOrders: SearchPurchaseOrder[];
  loading: boolean;
  totalHits: number;
  pageSize: number;
  page: number;
  sortOrder: number;
  sortField: string;
}>();

const { getUser } = useAuth();

const {
  selectedColumnsComputed,
  renderKey,
  onColumnReorder,
  resetColumns,
  orderedColumns,
  onColumnResizeEnd,
  onColumnSelected,
  onColumnUnselected,
} = useTablePreferences("purchaseOrderSearch", purchaseOrderSearchColumns, null, (await getUser()).getEmployee().id);

const filteredColumns = computed(() => {
  return orderedColumns.value.filter(
    (col) =>
      col.field !== PurchaseOrderListColumns.PurchaseOrderNumber &&
      col.field !== PurchaseOrderListColumns.SupplierName &&
      col.field !== PurchaseOrderListColumns.PDF
  );
});

const onRowContextMenu = (event: DataTableRowClickEvent) => {
  cm.value.show(event.originalEvent);
};

const purchaseOrderList = ref();
const selectedPurchaseOrder = ref<SearchPurchaseOrder>(new SearchPurchaseOrder());

const addGlobalSearchClass = (data: SearchPurchaseOrder) => {
  if (!data?.id) return ["c-search-result-row"];

  return [
    `c-search-result-row c-sr-id-${data?.id}`,
    { "c-search-result-row-selected": selectedPurchaseOrder.value?.id === data?.id },
  ];
};

const emit = defineEmits<{
  (e: "update:page", value: number): void;
  (e: "update:sortOrder", value: number): void;
  (e: "update:sortField", value: string): void;
  (e: "update:pageSize", value: number): void;
  (e: "downloadPdfBtnClicked", value: string): void;
  (e: "purchaseRefresh"): void;
}>();

watch([() => props.loading, () => props.purchaseOrders], ([loading, purchaseOrders]) => {
  if (loading === false && purchaseOrders.length > 0) {
    nextTick(() => {
      if (route.query?.focusResult) {
        focusSearchResult((route.query?.focusResult as string) ?? "");
      }
    });
  }
});

const focusSearchResult = (id = "") => {
  if (props.purchaseOrders.length > 0 && purchaseOrderList.value) {
    const element = id === "" ? null : purchaseOrderList.value.$el.querySelector(`tr.c-sr-id-${id}`);
    if (element) {
      element.focus();
      return;
    }
    purchaseOrderList.value.$el.querySelector("tbody tr:first-of-type").focus();
  }
};

const items = ref([
  {
    label: t("common.reload"),
    icon: "pi pi-refresh c-default-button c-circular-icon",
    class: "c-refresh-button",
    command: () => {
      onRefreshList();
    },
  },
  {
    label: "Column chooser",
    icon: "pi pi-list c-default-button c-circular-icon",

    class: "c-column-chooser",
    command: () => {
      visible.value = true;
    },
  },
]);
const openPurchaseOrder = (purchaseOrderId: string) => {
  purchaseOrderId != null && purchaseOrderId !== emptyUuid
    ? router.push({ name: "purchase-edit", params: { id: purchaseOrderId } })
    : toast.add({
        severity: "warn",
        summary: t("purchase.search.toast.open-purchase-failed"),
        detail: t("purchase.search.toast.no-purchase-selected"),
        closable: true,
      });
};

const selectAll = ref(false);
const onSelectAllChange = (event: DataTableSelectAllChangeEvent) => {
  selectAll.value = event.checked;
  selectedColumnsComputed.value = event.checked
    ? purchaseOrderSearchColumns
    : purchaseOrderSearchColumns.filter(
        (c) =>
          c.field === PurchaseOrderListColumns.PurchaseOrderNumber ||
          c.field === PurchaseOrderListColumns.SupplierName ||
          c.field === PurchaseOrderListColumns.PDF
      );
};

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (!(event.originalEvent instanceof KeyboardEvent)) {
    return;
  }
  if (event.originalEvent.key !== "Enter") {
    return;
  }
  openPurchaseOrder(event.data.id as string);
};

const onRowDblClicked = (event: DataTableRowDoubleClickEvent) => {
  openPurchaseOrder(event.data.id as string);
};

const onPurchaseNumberClicked = (purchaseOrder: SearchPurchaseOrder) => {
  selectedPurchaseOrder.value = purchaseOrder;
  openPurchaseOrder(purchaseOrder.id);
};

const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }
    focusSearchResult();

    emit("update:sortOrder", -props.sortOrder);
    emit("update:sortField", sortField);
    emit("update:page", 1);
  });
};

const onPage = async (event: DataTablePageEvent) => {
  nextTick(() => {
    focusSearchResult();
    emit("update:page", event.page + 1);
    emit("update:pageSize", event.rows);
  });
};

const onRefreshList = () => {
  emit("purchaseRefresh");
};
</script>

<style scoped lang="scss">
:deep(.p-paginator) {
  .p-paginator-first {
    margin-left: auto;
  }
  .p-paginator-current {
    margin-left: auto;
  }
}

:deep(.p-datatable-header) {
  background: transparent;
  border: none;
  padding: 0;
}

.c-pdf-icon img {
  display: inline-block;
  width: 1.68rem;
  cursor: pointer;
}
.c-purchase-order-number-text:hover {
  text-decoration: underline;
}
</style>
