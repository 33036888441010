import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import cloneDeep from "lodash.clonedeep";
import { useAuth } from "@cumulus/event-bus";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { NewPurchaseOrder } from "@/models/purchase-order/NewPurchaseOrder";
import { purchaseOrderApi } from "@/api/purchase-order/PurchaseOrderApi";
import { useErrorHandler } from "@/api/ErrorHandler";
import { useWarehouse } from "@/api/warehouse/WarehouseService";
import { useEmployee } from "@/api/employee/EmployeeService";
import { PurchaseOrderDelivery } from "@/models/purchase-order/PurchaseOrderDelivery";
import { Address } from "@/models/purchase-order/Address";
import { Employee } from "@/models/employee/Employee";

export const useNewPurchaseOrderStore = defineStore("newPurchaseOrderStore", () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();
  const { getEmployee } = useEmployee();
  const { getWarehouse } = useWarehouse();
  const { t } = useI18n();
  const toast = useCumulusToast(useToast());

  let initialPurchaseOrder = new NewPurchaseOrder();
  let employee: Employee | null = null;

  const newPurchaseOrder = ref<NewPurchaseOrder>(new NewPurchaseOrder());
  const selectedProductId = ref<string | null>(null);
  const advancedProductSearch = ref(false);
  const focusFirstOrderLine = ref(false);

  const loading = ref(false);
  const isSaving = ref(false);

  const clearStore = async () => {
    initialPurchaseOrder = new NewPurchaseOrder();
    newPurchaseOrder.value = new NewPurchaseOrder();
    await setEmployee();
    await setDefaultWarehouseAndDelivery();
    selectedProductId.value = null;
    advancedProductSearch.value = false;
  };

  const createOrder = async (): Promise<boolean> => {
    let success = true;
    try {
      isSaving.value = true;
      const authHeaders = await getAuthHeaders();

      const orderNumber = await purchaseOrderApi.create(authHeaders, newPurchaseOrder.value);
      toast.add({
        severity: "success",
        summary: t("purchase.add.toast.success.summary"),
        detail: t("purchase.add.toast.success.detail", { orderNumber: orderNumber }),
        closable: true,
      });

      await clearStore();
      initialPurchaseOrder = cloneDeep(newPurchaseOrder.value);
    } catch (error) {
      await handleError(error);
      success = false;
    } finally {
      isSaving.value = false;
    }

    return success;
  };

  const setEmployee = async () => {
    if (employee === null) {
      employee = await getEmployee();
    }

    const fullname = employee.firstName + " " + employee.lastName;
    const initials = employee.initials;

    newPurchaseOrder.value.registeredByEmployeeName = fullname;
    newPurchaseOrder.value.registeredByEmployeeInitials = initials;

    initialPurchaseOrder.registeredByEmployeeName = fullname;
    initialPurchaseOrder.registeredByEmployeeInitials = initials;
  };

  const setDefaultWarehouseAndDelivery = async () => {
    if (employee === null) {
      employee = await getEmployee();
    }

    newPurchaseOrder.value.warehouseId = employee.warehouseId;
    initialPurchaseOrder.warehouseId = employee.warehouseId;

    const warehouse = await getWarehouse(employee.warehouseId);
    const delivery = new PurchaseOrderDelivery();
    delivery.name = warehouse.name;
    delivery.address = Address.CreateFromWarehouseAddress(warehouse.address);

    newPurchaseOrder.value.delivery = delivery;
    initialPurchaseOrder.delivery = delivery;
  };

  const isPurchaseOrderChanged = computed(() => {
    return !deepEqual(newPurchaseOrder.value, initialPurchaseOrder);
  });

  const deepEqual = (x: unknown, y: unknown): boolean => {
    const ok = Object.keys,
      tx = typeof x,
      ty = typeof y;
    return x && y && tx === "object" && tx === ty
      ? ok(x as { [key: string]: unknown }).length === ok(y as { [key: string]: unknown }).length &&
          ok(x as { [key: string]: unknown }).every((key) =>
            deepEqual((x as { [key: string]: unknown })[key], (y as { [key: string]: unknown })[key])
          )
      : x === y;
  };

  return {
    isSaving,
    loading,
    newPurchaseOrder,
    selectedProductId,
    isPurchaseOrderChanged,
    advancedProductSearch,
    focusFirstOrderLine,
    clearStore,
    createOrder,
    setEmployee,
    setDefaultWarehouseAndDelivery,
  };
});
