<template>
  <BeforeUnloadBrowserTab :hasUpdates="isPurchaseOrderChanged" />
  <PurchaseHeader
    :isPurchaseOrderChanged="isPurchaseOrderChanged"
    :isSaving="isSaving"
    :allowEditPurchaseOrder="allowEditPurchaseOrder"
    :inGoodsReception="editPurchaseOrder.inGoodsReception"
    :purchaseOrderStatus="editPurchaseOrder.purchaseOrderStatus"
    :editMode="true"
    :unsavedChangesDialogVisible="unsavedChangesDialogVisible"
    @deleteClicked="onDeletePurchaseOrder"
    @onCancel="purchaseOrderRouteService.onCancel"
    @saveClicked="onSave"
    @stayOnPage="purchaseOrderRouteService.stayOnPage"
    @routeToPurchaseOrderSearch="purchaseOrderRouteService.routeToPurchaseOrderSearch"
  />
  <div class="c-purchase" data-testid="edit-purchase">
    <div class="c-overlay" v-if="loading">
      <div class="c-spinner-container">
        <ProgressSpinner />
      </div>
    </div>

    <div class="c-content">
      <div class="c-content-top">
        <div class="c-content-top-left mb-6">
          <Card class="c-card-font-size h-full m-2">
            <template #content>
              <div class="flex flex-wrap">
                <div class="c-col-full -mt-2 mb-2">
                  <PurchaseOrderInfo
                    :registered="editPurchaseOrder.registered"
                    :registeredBy="editPurchaseOrder.registeredByEmployeeName"
                    :purchaseOrderNumber="editPurchaseOrder.purchaseOrderNumber"
                    :purchaseOrderStatus="editPurchaseOrder.purchaseOrderStatus"
                  />
                </div>

                <div class="c-col-1 pr-6">
                  <div class="mt-4 mb-6 -mx-6">
                    <SupplierInput
                      :supplier="editPurchaseOrder.supplier"
                      :purchaseOrderLines="editPurchaseOrder.purchaseOrderLines"
                      :purchaseOrderStatus="editPurchaseOrder.purchaseOrderStatus"
                      v-model:focusSearchInput="focusSupplierSearchInput"
                      @selectedSupplier="selectedSupplier"
                    />
                  </div>

                  <div class="mb-6 -mx-6">
                    <ContactInput
                      :contact="editPurchaseOrder.supplier.contact"
                      :supplierContacts="supplierContacts"
                      @selectedContact="selectedContact"
                      :purchaseOrderStatus="editPurchaseOrder.purchaseOrderStatus"
                    />
                  </div>

                  <div class="mb-2 -mx-6">
                    <ContactEmailInput
                      v-model:email="editPurchaseOrder.supplier.contact.email"
                      :purchaseOrderStatus="editPurchaseOrder.purchaseOrderStatus"
                    />
                  </div>
                </div>

                <div class="c-col-2">
                  <div class="-mt-1.5">
                    <InformationTabs
                      v-model:delivery="editPurchaseOrder.delivery"
                      :supplier="editPurchaseOrder.supplier"
                      :purchaseOrderStatus="editPurchaseOrder.purchaseOrderStatus"
                    />
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </div>

        <div class="c-content-top-right mb-6">
          <Card class="c-card-font-size h-full m-2">
            <template #content>
              <div class="flex flex-wrap">
                <div class="c-col-1 pr-6 -mx-4">
                  <div class="mt-4 mb-6">
                    <PurchaseOrderReference
                      v-model:purchaseOrderReference="editPurchaseOrder.purchaseOrderReference"
                      :purchaseOrderStatus="editPurchaseOrder.purchaseOrderStatus"
                    />
                  </div>

                  <div class="mb-6">
                    <PurchaseWarehouse
                      v-model:warehouseId="editPurchaseOrder.warehouseId"
                      @updatePurchaseOrderDelivery="updatePurchaseOrderDelivery($event)"
                      :purchaseOrderLines="editPurchaseOrder.purchaseOrderLines"
                      :purchaseOrderStatus="editPurchaseOrder.purchaseOrderStatus"
                    />
                  </div>

                  <div class="flex flex-wrap">
                    <div class="c-col-1 -mr-6">
                      <div class="mb-2 mr-2">
                        <FreightMethod
                          v-model:freightMethod="editPurchaseOrder.freightMethod"
                          :defaultFreightMethodId="freightMethodIdFromSupplier"
                          :purchaseOrderStatus="editPurchaseOrder.purchaseOrderStatus"
                        />
                      </div>
                    </div>
                    <div class="c-col-2 -mr-6">
                      <div class="mb-2 mr-6">
                        <ShippingPrice
                          class="px-6"
                          v-model:shippingPrice="editPurchaseOrder.shippingPrice"
                          :currencyIso="editPurchaseOrder.currencyIso"
                          :purchaseOrderStatus="editPurchaseOrder.purchaseOrderStatus"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="c-col-2 -mx-4">
                  <div class="mr-2 mb-6">
                    <CommentInput
                      v-model:comment="editPurchaseOrder.comment"
                      :inGoodsReception="editPurchaseOrder.inGoodsReception"
                      :purchaseOrderStatus="editPurchaseOrder.purchaseOrderStatus"
                    />
                  </div>

                  <div class="mt-4 mb-6 mr-2">
                    <Incoterms
                      v-model:selectedIncoterms="editPurchaseOrder.supplier.incoterms"
                      :purchaseOrderStatus="editPurchaseOrder.purchaseOrderStatus"
                    />
                  </div>

                  <div class="flex flex-wrap">
                    <div class="c-col-1 -mr-6">
                      <div class="mb-2 mr-4">
                        <PaymentTerms
                          v-model:paymentTerm="editPurchaseOrder.paymentTerm"
                          :paymentTermIdFromSupplier="paymentTermIdFromSupplier"
                          :purchaseOrderStatus="editPurchaseOrder.purchaseOrderStatus"
                        />
                      </div>
                    </div>
                    <div class="c-col-2 -mr-6">
                      <div class="mb-2 mr-8">
                        <PurchaseCurrency
                          v-model:currencyIso="editPurchaseOrder.currencyIso"
                          :currencies="currencies"
                          :purchaseOrderStatus="editPurchaseOrder.purchaseOrderStatus"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </div>
      </div>

      <div class="c-purchase-order-lines-content">
        <Card class="c-card-font-size m-2">
          <template #content>
            <div class="c-purchase-order-lines-card">
              <div class="c-purchase-order-tables">
                <Suspense>
                  <PurchaseOrderLines
                    v-model:purchaseOrderLines="editPurchaseOrder.purchaseOrderLines"
                    :supplierId="editPurchaseOrder.supplier.id"
                    :purchaseOrderStatus="editPurchaseOrder.purchaseOrderStatus"
                    :goodsReceptions="goodsReceptions"
                    :allowEdit="allowEditPurchaseOrder"
                    :employeeId="employeeId"
                    :currencyIso="editPurchaseOrder.currencyIso"
                    @purchaseOrderLineDeleted="deletePurchaseOrderLine"
                    @purchaseOrderPriceUpdated="onPurchaseOrderPriceUpdated"
                    ref="purchaseLinesRef"
                  />
                </Suspense>

                <ProductSearch
                  :purchaseOrderLines="editPurchaseOrder.purchaseOrderLines"
                  :allowSearch="allowEditPurchaseOrder"
                  :selectedSupplierId="editPurchaseOrder.supplier.id"
                  :warehouseId="editPurchaseOrder.warehouseId"
                  :currencies="currencies"
                  :selectedCurrencyIso="editPurchaseOrder.currencyIso"
                  @createNewOrderLine="createNewOrderLine"
                  ref="productSearchRef"
                />
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>

  <PurchaseFooter
    :sumTotalLines="editPurchaseOrder.sumTotalLines"
    :totalSum="editPurchaseOrder.totalSum"
    v-model:shippingPrice="editPurchaseOrder.shippingPrice"
    :purchaseOrderStatus="editPurchaseOrder.purchaseOrderStatus"
    :currencyIso="editPurchaseOrder.currencyIso"
    @reCalculatePurchaseOrderTotal="reCalculatePurchaseOrderTotal"
    :allowEdit="allowEditPurchaseOrder"
  />
</template>

<script setup lang="ts">
import { ref, onMounted, nextTick } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import useValidate from "@vuelidate/core";
import { ShortcutAction, useShortcut } from "@cumulus/shortcut";
import { BeforeUnloadBrowserTab } from "@cumulus/components";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";

//GoodsReception
import { GoodsReception } from "@/models/goods-reception/GoodsReception";
import { useGoodsReception } from "@/api/goods-reception/GoodsReceptionService";

//PurchaseOrder
import { useEditPurchaseOrderStore } from "@/stores/EditPurchaseOrderStore";
import { PurchaseOrderLine } from "@/models/purchase-order/PurchaseOrderLine";
import { PurchaseOrderSupplier } from "@/models/purchase-order/PurchaseOrderSupplier";
import { Address } from "@/models/purchase-order/Address";
import { Warehouse } from "@/models/warehouse/Warehouse";
import { usePurchaseOrderRouteService } from "@/api/purchase-order/PurchaseOrderRouteService";

//Supplier
import { Supplier } from "@/models/supplier/Supplier";
import { SupplierContact } from "@/models/supplier/SupplierContact";
import { useSupplier } from "@/api/supplier/SupplierService";

//Calculations
import { usePurchaseOrderCalculation } from "@/utils/calculation/PurchaseOrderCalculation";
import { CalculationBase } from "@/models/purchase-order/calculations/CalculationBase";

//Components
import SupplierInput from "@/components/supplier/SupplierInput.vue";
import InformationTabs from "@/components/info-tabs/InformationTabs.vue";
import PurchaseCurrency from "@/components/PurchaseCurrency.vue";
import Incoterms from "@/components/Incoterms.vue";
import PurchaseOrderLines from "@/components/PurchaseOrderLines.vue";
import PurchaseOrderReference from "@/components/PurchaseOrderReference.vue";
import ContactInput from "@/components/contact/ContactInput.vue";
import ContactEmailInput from "@/components/contact/ContactEmailInput.vue";
import PaymentTerms from "@/components/PaymentTerms.vue";
import CommentInput from "@/components/comment/CommentInput.vue";
import FreightMethod from "@/components/FreightMethod.vue";
import ShippingPrice from "@/components/ShippingPrice.vue";
import PurchaseHeader from "@/components/PurchaseOrderHeader.vue";
import PurchaseOrderInfo from "@/components/PurchaseOrderInfo.vue";
import PurchaseFooter from "@/components/PurchaseOrderFooter.vue";
import PurchaseWarehouse from "@/components/PurchaseWarehouse.vue";
import ProductSearch from "@/components/ProductSearch.vue";

import { SearchProductForPurchaseOrder } from "@/models/search/product/SearchProductForPurchaseOrder";
import { Currency } from "@/models/currency/Currency";
import { useCurrencyService } from "@/api/currency/CurrencyService.ts";
import { useAuth } from "@cumulus/event-bus";

const { getUser } = useAuth();

const val = useValidate();
const { t } = useI18n();
const route = useRoute();
const currencies = ref<Currency[]>([]);
const supplierContacts = ref<SupplierContact[]>([]);
const freightMethodIdFromSupplier = ref<string>("");
const paymentTermIdFromSupplier = ref<string>("");
const goodsReceptions = ref<GoodsReception[]>([]);
const purchaseLinesRef = ref();
const productSearchRef = ref();
const focusSupplierSearchInput = ref(true);

const { getAllCurrencies } = useCurrencyService();
const { getGoodsReceptionsForPurchaseOrder } = useGoodsReception();
const { getSupplier } = useSupplier();

const editPurchaseOrderStore = useEditPurchaseOrderStore();
const { editPurchaseOrderId, editPurchaseOrder, isPurchaseOrderChanged, isSaving, loading, allowEditPurchaseOrder } =
  storeToRefs(editPurchaseOrderStore);

const purchaseOrderRouteService = usePurchaseOrderRouteService(editPurchaseOrderId, isPurchaseOrderChanged, () =>
  editPurchaseOrderStore.clearStore()
);
const { unsavedChangesDialogVisible } = purchaseOrderRouteService;

const { calculateSumLineTotalPrice, calculateSumTotalLines, calculateTotalSum } = usePurchaseOrderCalculation();

const onPurchaseOrderPriceUpdated = (purchaseOrderLine: PurchaseOrderLine, calculationBase: CalculationBase) => {
  const sumLine = calculateSumLineTotalPrice(purchaseOrderLine, calculationBase);
  const line = editPurchaseOrder.value.purchaseOrderLines.find((line) => line.id === purchaseOrderLine.id);
  if (line) {
    line.purchasePrice = purchaseOrderLine.purchasePrice;
    line.sumLine = sumLine;
  }

  editPurchaseOrder.value.sumTotalLines = calculateSumTotalLines(editPurchaseOrder.value.purchaseOrderLines);
  editPurchaseOrder.value.totalSum = calculateTotalSum(
    editPurchaseOrder.value.purchaseOrderLines,
    editPurchaseOrder.value.shippingPrice
  );
};

const reCalculatePurchaseOrderTotal = () => {
  editPurchaseOrder.value.sumTotalLines = calculateSumTotalLines(editPurchaseOrder.value.purchaseOrderLines);
  editPurchaseOrder.value.totalSum = calculateTotalSum(
    editPurchaseOrder.value.purchaseOrderLines,
    editPurchaseOrder.value.shippingPrice
  );
};

const updatePurchaseOrderDelivery = (warehouse: Warehouse) => {
  if (warehouse) {
    editPurchaseOrder.value.delivery.name = warehouse.name;
    editPurchaseOrder.value.delivery.address = Address.CreateFromWarehouseAddress(warehouse.address);
  }
};

const toast = useCumulusToast(useToast());

const onSave = async () => {
  //Wait for input onblur functions and calculations to finish
  if (document.activeElement?.localName === "input") {
    (document.activeElement as HTMLElement).blur();
  }

  val.value.$touch();
  if (!(await val.value.$validate())) {
    toast.add({
      severity: "warn",
      summary: t("purchase.edit.toast.validation.summary"),
      detail: t("purchase.edit.toast.validation.detail"),
      closable: true,
    });
    return;
  }

  if (await editPurchaseOrderStore.updateOrder()) {
    purchaseOrderRouteService.routeToPurchaseOrderSearch();
  }
};

const createNewOrderLine = async (product: SearchProductForPurchaseOrder) => {
  const purchaseOrderLine = PurchaseOrderLine.Create(product);
  purchaseOrderLine.quantity = product.quantity ?? 1;
  purchaseOrderLine.purchasePrice = product.supplierPurchasePrice ?? 0;
  purchaseOrderLine.shippingDate = product.shippingDate ?? new Date(Date.now()).toDateOnlyString();
  purchaseOrderLine.estimatedArrivalDate = product.estimatedArrivalDate ?? new Date(Date.now()).toDateOnlyString();
  editPurchaseOrder.value.purchaseOrderLines.push(purchaseOrderLine);
  purchaseOrderLine.positionNumber = editPurchaseOrder.value.purchaseOrderLines.length;

  await reCalculatePurchaseOrderTotal();
  await nextTick();
  productSearchRef.value.selectProductSearchInput();
};

const deletePurchaseOrderLine = async (purchaseLine: PurchaseOrderLine) => {
  const index = editPurchaseOrder.value.purchaseOrderLines?.findIndex((item) => item.id === purchaseLine.id);
  if (index !== -1) {
    editPurchaseOrder.value.purchaseOrderLines?.splice(index, 1);
    for (let index = 0; index < editPurchaseOrder.value.purchaseOrderLines.length; index++) {
      editPurchaseOrder.value.purchaseOrderLines[index].positionNumber = index + 1;
    }

    await reCalculatePurchaseOrderTotal();
  }
};

const fetchGoodsReceptionsForPurchaseOrder = async () => {
  const purchaseOrderId = route.params.id as string;
  goodsReceptions.value = await getGoodsReceptionsForPurchaseOrder(purchaseOrderId);
};

const fetchCurrencies = async () => {
  currencies.value = await getAllCurrencies();
};

const employeeId = ref<string>("");
onMounted(async () => {
  employeeId.value = (await getUser()).getEmployee().id;
  if ((await editPurchaseOrderStore.getOrder(route.params.id as string)) === null) {
    purchaseOrderRouteService.routeToPurchaseOrderSearch();
    return;
  }

  const supplier = await getSupplier(editPurchaseOrder.value.supplier.id);
  supplierContacts.value = supplier.contacts;

  await fetchGoodsReceptionsForPurchaseOrder();
  await fetchCurrencies();

  await reCalculatePurchaseOrderTotal();
});

const onDeletePurchaseOrder = async () => {
  if (await editPurchaseOrderStore.deleteOrder(editPurchaseOrder.value.id)) {
    purchaseOrderRouteService.routeToPurchaseOrderSearch();
  }
};

useShortcut(ShortcutAction.save, onSave);

const selectedSupplier = (supplier: Supplier) => {
  editPurchaseOrder.value.supplier = PurchaseOrderSupplier.createFromSupplier(supplier);
  supplierContacts.value = supplier.contacts;
  editPurchaseOrder.value.currencyIso = supplier.currency?.name ?? "";

  paymentTermIdFromSupplier.value = supplier.defaultPaymentTermId;
  freightMethodIdFromSupplier.value = supplier.freightMethodId;
};

const selectedContact = (contact: SupplierContact) => {
  editPurchaseOrder.value.supplier.contact = contact;
};
</script>

<style lang="scss" scoped>
.c-purchase {
  margin: var(--default-content-margin);
  margin-top: 0;
  margin-bottom: 0;
}

.c-content-top {
  display: flex;
  flex-wrap: wrap;
}
.c-content-top-left {
  flex: 50%;

  .c-card-font-size {
    min-height: 15rem;
  }
}
.c-content-top-right {
  flex: 50%;

  .c-card-font-size {
    min-height: 15rem;
  }
}

.c-col-full {
  flex: 100%;
}

.c-col-1 {
  flex: 50%;
}
.c-col-2 {
  flex: 50%;
}

@media (max-width: 1024px) {
  .c-content-top-left,
  .c-content-top-right {
    flex: 100%;
  }
}
.c-purchase-order-lines-card {
  margin-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.c-card-font-size {
  font-size: 12.8px;
}

.c-content {
  position: relative;
  transition: all 0.25s;
}

.c-spinner-container {
  position: relative;
  top: 175px;
}
</style>
