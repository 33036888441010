import { onMounted, onUnmounted } from "vue";
import { PurchaseOrderShortcutAction } from "./PurchaseOrderShortcutAction";

export const usePurchaseShortcuts = (action: PurchaseOrderShortcutAction, callback: () => void) => {
  const handleShortcuts = (e: KeyboardEvent) => {
    switch (action) {
      case PurchaseOrderShortcutAction.SelectProductSearchInput:
        if (e.altKey && e.key === "s") {
          e.preventDefault();
          callback();
        }
        break;
      default:
        break;
    }
  };
  onMounted(() => {
    window.addEventListener("keydown", handleShortcuts);
  });
  onUnmounted(() => {
    window.removeEventListener("keydown", handleShortcuts);
  });
};
