import { httpClient, AuthHeaders } from "@cumulus/http";
import { Supplier } from "../../models/supplier/Supplier";

class SupplierApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_PRODUCT != "undefined" && import.meta.env.VITE_APP_API_PRODUCT
        ? `${import.meta.env.VITE_APP_API_PRODUCT}/suppliers`
        : `${import.meta.env.VITE_APP_API_URL as string}/suppliers`;
  }

  public async get(authHeaders: AuthHeaders, id: string): Promise<Supplier> {
    return await httpClient(this.uri, authHeaders)
      .get(id)
      .then((response) => response.data);
  }

  public async getSuppliersByIds(authHeaders: AuthHeaders, ids: string[]): Promise<Supplier[]> {
    return await httpClient(this.uri, authHeaders)
      .post("/by-ids", ids)
      .then((response) => response.data);
  }
}
const supplierApi = new SupplierApi();
export { supplierApi };
