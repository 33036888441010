import { SupplierSearchFilters } from "./SupplierSearchFilters";

export class SupplierSearchRequest {
  query = "";
  page = 1;
  pageSize = 25;
  filters = new SupplierSearchFilters();

  constructor(query: string) {
    this.query = query;
  }
}
