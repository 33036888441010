<template>
  <FloatLabelDropdown
    id="purchase-incoterms"
    dataTestId="purchase-incoterms"
    v-model:value="selectedIncoterms"
    class="mr-7"
    :options="dropdownOptions"
    :label="t(`purchase.incoterms`)"
    :placeholder="t('placeholder.select', { property: t('purchase.incoterms').toLowerCase() })"
    :isFloatingLabel="isFloatingLabel"
    :disabled="!allowEdit"
    :class="{ 'p-disabled': !allowEdit }"
  />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed, onMounted, ref } from "vue";
import { Incoterm as Incoterms } from "@/models/supplier/Incoterm";
import { useIncoterms } from "@/api/incoterms/IncotermsService";
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";

const props = defineProps<{
  selectedIncoterms: Incoterms | null;
  purchaseOrderStatus?: PurchaseOrderStatus;
}>();

const emit = defineEmits<{
  (e: "update:selectedIncoterms", value: Incoterms | null): void;
}>();

const dropdownOptions = computed(() => {
  return allIncoterms.value.map((incoterm) => {
    return { name: incoterm.description, value: incoterm.code };
  });
});

const { t } = useI18n();
const { getAllIncoterms } = useIncoterms();

const allIncoterms = ref<Incoterms[]>([]);

const selectedIncoterms = computed<string>({
  get: () => {
    return allIncoterms.value.find((c) => c.code === props.selectedIncoterms?.code)?.code ?? "";
  },
  set: (code: string) => {
    if (code === "") {
      return;
    }

    const incoterm = allIncoterms.value.find((c) => c.code === code);

    if (incoterm && allowEdit.value === true) {
      emit("update:selectedIncoterms", incoterm);
    }
  },
});

const allowEdit = computed<boolean>(() => {
  return !props.purchaseOrderStatus || props.purchaseOrderStatus === PurchaseOrderStatus.Open;
});

const fetchIncoterms = async () => {
  allIncoterms.value = await getAllIncoterms();
};
onMounted(fetchIncoterms);

const isFloatingLabel = computed(() => {
  if (selectedIncoterms.value === undefined) {
    return false;
  }

  return selectedIncoterms.value !== "";
});
</script>
