import { PurchaseOrderLine } from "@/models/purchase-order/PurchaseOrderLine";

export class PriceCalculation {
  public static bankersRounding(num: number, decimalPlaces: number): number {
    const d = decimalPlaces || 0;
    const m = Math.pow(10, d);
    const n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
    const i = Math.floor(n),
      f = n - i;
    const e = 1e-8; // Allow for rounding errors in f
    const r = f > 0.5 - e && f < 0.5 + e ? (i % 2 == 0 ? i : i + 1) : Math.round(n);
    return d ? r / m : r;
  }

  public static calculateOrderLineTotalPrice(quantity: number, purchasePrice: number, decimalPlaces: number): number {
    const sumLine = purchasePrice * quantity;
    return PriceCalculation.bankersRounding(sumLine, decimalPlaces);
  }

  public static calculateTotalPurchaseOrderLines(
    purchaseOrderLine: PurchaseOrderLine[],
    decimalPlaces: number
  ): number {
    if (purchaseOrderLine.length == 0) {
      return 0;
    }

    const sum = purchaseOrderLine.reduce((accumulator, x) => {
      return accumulator + x.sumLine;
    }, 0);

    return PriceCalculation.bankersRounding(sum, decimalPlaces);
  }

  public static calculateSumOfUnreceivedLines(purchaseOrderLine: PurchaseOrderLine[], decimalPlaces: number): number {
    if (purchaseOrderLine.length == 0) {
      return 0;
    }

    const openLinesSum = purchaseOrderLine.reduce((accumulator, x) => {
      const value = (x.quantity - x.receivedQuantity) * x.purchasePrice;
      return accumulator + value;
    }, 0);

    return PriceCalculation.bankersRounding(openLinesSum, decimalPlaces);
  }

  public static calculateTotalPurchaseOrderSum(
    purchaseOrderLine: PurchaseOrderLine[],
    shippingPrice: number,
    decimalPlaces: number
  ): number {
    if (purchaseOrderLine.length == 0) {
      return 0;
    }

    const totalPurchaseOrderLinesSum = purchaseOrderLine.reduce((accumulator, x) => {
      return accumulator + x.sumLine;
    }, 0);

    const totalPurchaseOrderTotalSum = totalPurchaseOrderLinesSum + shippingPrice;
    return PriceCalculation.bankersRounding(totalPurchaseOrderTotalSum, decimalPlaces);
  }
}
