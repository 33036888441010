<template>
  <PrimeButton class="c-circular-button mr-4" @click="emit('onAddPurchase')" data-testid="btn-add">
    <i class="pi pi-plus c-success-button c-circular-icon"></i>
    <span class="px-4">{{ t("purchase.add-purchase") }}</span>
  </PrimeButton>

  <PrimeButton class="c-circular-button" @click="emit('onOpenReceivedGoods')" data-testid="c-open-received-goods">
    <i class="pi pi-list c-default-button c-circular-icon"></i>
    <span class="px-4">{{ t("common.open-received-goods") }}</span>
  </PrimeButton>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const emit = defineEmits<{
  (e: "onAddPurchase"): void;
  (e: "onOpenReceivedGoods"): void;
}>();

const { t } = useI18n();
</script>

<style scoped lang="scss">
.c-default-btn-bg {
  background-color: var(--default-btn-bg);
}
</style>
