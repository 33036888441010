<template>
  <div class="sticky z-[1] bottom-0 w-ful ml-4 mr-2 bg-surface-100 dark:bg-surface-800">
    <Card
      class="border-t-0 rounded-t-none -mt-5 mb-2 shadow-[0px_2px_3px_0px_rgba(0,0,0,0.1),0px_3px_2px_-4px_rgba(0,0,0,0.1)]"
    >
      <template #content>
        <div class="bg-[#E7F0F7] dark:bg-transparent rounded-[4px] w-full">
          <div class="flex flex-row-reverse flex-wrap mr-6">
            <div class="flex justify-between gap-20">
              <div class="text-center">
                <div>{{ t("purchase.footer.total-items") }}</div>
                <span class="font-bold" data-testid="purchase-order-items-summary">{{
                  n(sumTotalLines, "currency", currencyIsoComputed)
                }}</span>
              </div>

              <div class="text-center">
                <div for="purchase-agreed-freight">{{ t("purchase.footer.freight") }}</div>
                <span class="font-bold">{{ n(freightPriceComputed, "currency", currencyIsoComputed) }}</span>
              </div>

              <div class="text-center">
                <div>{{ t("purchase.footer.total-sum") }}</div>
                <span class="font-bold text-lg" data-testid="purchase-order-total-summary">{{
                  n(totalSum, "currency", currencyIsoComputed)
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  sumTotalLines: number;
  totalSum: number;
  shippingPrice: number;
  purchaseOrderStatus?: PurchaseOrderStatus;
  allowEdit: boolean;
  currencyIso: string;
}>();

const emit = defineEmits<{
  (e: "update:shippingPrice", value: number): void;
  (e: "reCalculatePurchaseOrderTotal"): void;
}>();

const { t, n } = useI18n();

const currencyIsoComputed = computed(() => {
  return props.currencyIso ? props.currencyIso : "NOK";
});

const freightPriceComputed = computed({
  get() {
    return props.shippingPrice ?? 0;
  },
  set(value: number) {
    if (value != null) {
      emit("update:shippingPrice", value);
      emit("reCalculatePurchaseOrderTotal");
    }
  },
});
</script>
