<template>
  <div class="mt-2">
    <FloatLabelInputNumber
      id="shipping-price"
      :label="t('purchase.shipping-price')"
      v-model:value="shippingPrice"
      :placeholder="t('placeholder.type', { property: t('purchase.shipping-price').toLowerCase() })"
      dataTestId="shipping-price"
      :class="{ 'p-disabled': !allowEdit }"
      :disabled="!allowEdit"
      :isFloatingLabel="true"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";

const { t } = useI18n();

const props = defineProps<{
  currencyIso: string;
  purchaseOrderStatus?: PurchaseOrderStatus;
}>();

const shippingPrice = defineModel<number>("shippingPrice");

const allowEdit = computed<boolean>(() => {
  return !props.purchaseOrderStatus || props.purchaseOrderStatus === PurchaseOrderStatus.Open;
});
</script>

<style lang="scss" scoped>
.c-inline-title {
  min-width: 7.1rem;
}
</style>
