<template>
  <PrimeDialog
    v-model:visible="visible"
    :header="t('purchase.product.info-dialog-header')"
    :modal="true"
    :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
    class="c-product-info"
    @keydown.esc.stop="onCancel"
    data-testid="product-info-dialog"
    :loading="loadingProduct"
  >
    <div class="c-product-info-dialog">
      <div class="flex flex-wrap">
        <div class="col-span-12 p-0">
          <div class="c-fields-container">
            <div class="flex mt-4">
              <div class="col-span-6">
                <FloatLabelInput
                  :label="t('purchase.product.number')"
                  id="product-number"
                  dataTestId="product-number"
                  :disabled="true"
                  :value="product?.productNumber"
                />
              </div>
              <div class="col-span-6">
                <FloatLabelInput
                  :label="t('purchase.product.gtin')"
                  dataTestId="product-gti-number"
                  :value="product?.gtin"
                  :disabled="true"
                />
              </div>
            </div>
            <div class="col-span-12">
              <FloatLabelInput
                :label="t('purchase.product.name')"
                id="product-name"
                dataTestId="product-name"
                :value="product?.name"
                :disabled="true"
              />
              <div class="mt-4">
                <FloatLabelInput
                  :label="t(`purchase.product.description`)"
                  dataTestId="product-description"
                  :value="product?.description"
                  :disabled="true"
                />
              </div>
              <div class="mt-4">
                <FloatLabelInput
                  :label="t(`purchase.product.group-level`)"
                  dataTestId="product-group"
                  :disabled="true"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="flex col-span-12 p-0 mt-6">
          <div class="col-span-5 p-0">
            <div class="c-fields-container p-2">
              <div class="mt-4">
                <FloatLabelInput
                  :label="t('purchase.product.manufacturer')"
                  dataTestId="product-manufacturer"
                  :disabled="true"
                />
              </div>
              <div class="mt-4">
                <FloatLabelInput :label="t('purchase.product.type')" dataTestId="product-type" :disabled="true" />
              </div>
              <div class="mt-4">
                <FloatLabelInput :label="t('purchase.product.unit')" dataTestId="product-unit" :disabled="true" />
              </div>
            </div>
            <div class="c-fields-container mt-4 p-2">
              <ProductDimensions :dimension="product?.dimension" :weight="product?.weight" />
            </div>
          </div>
          <div class="col-span-7 p-0 pl-6">
            <div class="c-fields-container h-full">
              <Galleria
                ref="galleria"
                v-model:activeIndex="activeIndex"
                :value="product?.productImages"
                :containerStyle="{ 'max-width': '100%', height: '100%' }"
                :showThumbnails="true"
                :numVisible="fullScreen ? 6 : 2"
                :showItemNavigators="fullScreen"
                :circular="true"
                :transitionInterval="3000"
                :pt="{
                  root: {
                    class: [{ 'flex flex-col': fullScreen }],
                  },
                  content: {
                    class: ['relative', { 'flex-1 justify-center': fullScreen }],
                    style: { height: '100%' },
                  },
                  thumbnailwrapper: 'absolute w-full left-0 bottom-0',
                  previousThumbnailButton: { tabindex: '-1' },
                  nextThumbnailButton: { tabindex: '-1' },
                }"
              >
                <template #item="slotProps">
                  <img
                    :src="resizeImage(slotProps.item.url, 200, 200)"
                    :alt="slotProps.item.name"
                    :style="[
                      {
                        maxWidth: !fullScreen ? '150px' : '50vw',
                        height: !fullScreen ? '200px' : '60vh',
                        borderTopLeftRadius: !fullScreen ? '12px' : '',
                        borderBottomLeftRadius: !fullScreen ? '12px' : '',
                      },
                    ]"
                  />
                </template>
                <template #thumbnail="slotProps">
                  <div class="grid grid-cols-12 gap-4 grid-nogutter">
                    <img
                      :src="resizeImage(slotProps.item.url, 50, 50)"
                      :alt="slotProps.item.name"
                      style="height: 50px; width: 50px; display: block"
                    />
                  </div>
                </template>
              </Galleria>
            </div>
          </div>
        </div>
        <div v-if="product?.description !== ''" class="c-fields-container col-span-12 p-4 flex flex-col mt-6">
          <span class="text-center"> Additional info:</span>
          {{ product?.description }}
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex flex-row-reverse justify-between">
        <div class="flex items-center justify-center">
          <PrimeButton
            :label="t(`common.close`)"
            data-testid="delivery-dialog-cancel-btn"
            @click="onCancel"
            class="c-dialog-default-button"
            tabindex="1"
            autofocus
          />
        </div>
      </div>
    </template>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import ProductDimensions from "./ProductDimensions.vue";
import { useImageService } from "@/api/image/ImageService";
import Galleria from "primevue/galleria";
import { useProduct } from "@/api/product/ProductService";
import { Product } from "@/models/product/Product";

const { resizeImage } = useImageService();
const { t } = useI18n();
const { getProductById } = useProduct();

const activeIndex = ref(0);
const fullScreen = ref(false);
const product = ref<Product>();
const loadingProduct = ref(false);

const props = defineProps<{
  showDialog: boolean;
  productId: string | null;
}>();

const emit = defineEmits<{
  (e: "update:showDialog", value: boolean): void;
}>();

const visible = computed<boolean>({
  get: () => props.showDialog,
  set: (value) => emit("update:showDialog", value),
});

const onCancel = () => {
  visible.value = false;
};

onMounted(async () => {
  if (props.productId !== "" && props.productId !== null) {
    loadingProduct.value = true;
    try {
      product.value = await getProductById(props.productId);
    } finally {
      loadingProduct.value = false;
    }
  }
});
</script>

<style lang="scss">
.c-product-info-dialog {
  width: 35vw;
}
.c-fields-container {
  border: var(--field-group-border);
  border-radius: var(--field-group-border-radius);
}
</style>
