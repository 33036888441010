import { useAuth, loginRequest, InteractionRequiredAuthError } from "@cumulus/event-bus";
import { BadRequestError, InternalServerError, NotFoundError } from "@cumulus/http";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { useI18n } from "vue-i18n";
import { ApiRequestError } from "@cumulus/http/dist/error-handler";

export function useErrorHandler() {
  const { instance } = useAuth();
  const toast = useCumulusToast(useToast());
  const { t } = useI18n();

  const handleError = async (error: unknown): Promise<void> => {
    if (error instanceof InteractionRequiredAuthError) {
      await instance.acquireTokenRedirect(loginRequest);
      return;
    }

    const summary = t("common.error-detail.error-occured");

    let errorMessage = "";
    if (error instanceof InternalServerError) {
      errorMessage = t("common.error-detail.server-error");
    } else if (error instanceof NotFoundError) {
      errorMessage = t("common.error-detail.not-found");
    } else if (error instanceof BadRequestError) {
      const errorMessages: string[] = error.message.map((message: ApiRequestError) => message.errorMessage);

      if (errorMessages.length > 0) {
        errorMessages.forEach((m) =>
          toast.add({
            severity: "error",
            summary: summary,
            detail: m,
            closable: true,
          })
        );
      } else {
        errorMessage = t("common.error-detail.bad-request");
      }
    } else {
      errorMessage = t("common.error-detail.unknown");
    }

    if (errorMessage !== "") {
      toast.add({
        severity: "error",
        summary: summary,
        detail: errorMessage,
        closable: true,
      });
    }
  };

  return { handleError };
}
