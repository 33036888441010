import { httpClient, AuthHeaders } from "@cumulus/http";
import { SupplierSearchRequest } from "../../models/search/supplier/SupplierSearchRequest";
import { SupplierSearchResponse } from "../../models/search/supplier/SupplierSearchResponse";
import { ProductSearchRequest } from "../../models/search/product/ProductSearchRequest";
import { PurchaseOrderSearchRequest } from "../../models/search/purchase-order/PurchaseOrderSearchRequest";
import { PurchaseOrderSearchResponse } from "../../models/search/purchase-order/PurchaseOrderSearchResponse";
import { ProductSearchResponse } from "../../models/search/product/ProductSearchResponse";

class SearchApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_SEARCH != "undefined" && import.meta.env.VITE_APP_API_SEARCH
        ? import.meta.env.VITE_APP_API_SEARCH
        : `${import.meta.env.VITE_APP_API_URL as string}`;
  }

  public async supplierSearch(
    authHeaders: AuthHeaders,
    request: SupplierSearchRequest
  ): Promise<SupplierSearchResponse> {
    return await httpClient(this.uri, authHeaders)
      .post("/suppliers/search", request)
      .then(({ data }) => data);
  }

  public async productSearch(authHeaders: AuthHeaders, request: ProductSearchRequest): Promise<ProductSearchResponse> {
    return await httpClient(this.uri, authHeaders)
      .post("/products/search", request)
      .then(({ data }) => data);
  }

  public async purchaseOrderSearch(
    authHeaders: AuthHeaders,
    request: PurchaseOrderSearchRequest
  ): Promise<PurchaseOrderSearchResponse> {
    return await httpClient(this.uri, authHeaders)
      .post("/purchase-orders/search", request)
      .then(({ data }) => data);
  }
}

const searchApi = new SearchApi();
export { searchApi };
