import { ProductGroupFilter } from "./ProductGroupFilter";
import { ProductStatus } from "./ProductStatus";

export class ProductSearchFilters {
  manufacturers: string[] = [];
  productStatuses: ProductStatus[] = [];
  productNumbers: string[] = [];
  productNames: string[] = [];
  productGroups: ProductGroupFilter = new ProductGroupFilter();
  onlyProductsWithBackOrders = false;
  suppliers: string[] = [];
  warehouses: string[] = [];
}
