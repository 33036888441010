import { useAuth } from "@cumulus/event-bus";
import { goodsReceptionApi } from "./GoodsReceptionApi";
import { useErrorHandler } from "../ErrorHandler";
import { ReceivedGoods } from "../../models/purchase-order/ReceivedGoods";
import { GoodsReception } from "../../models/goods-reception/GoodsReception";

export function useGoodsReception() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getReceivedGoods = async (): Promise<ReceivedGoods[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await goodsReceptionApi.getReceivedGoods(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getGoodsReceptionsForPurchaseOrder = async (id: string): Promise<GoodsReception[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await goodsReceptionApi.getGoodsReceptionsForPurchaseOrder(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getReceivedGoods,
    getGoodsReceptionsForPurchaseOrder,
  };
}
