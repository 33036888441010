<template>
  <Popover
    ref="contactPanelRef"
    appendTo="body"
    :dismissable="true"
    id="contact_overlay_panel"
    :breakpoints="{ '960px': '75vw', '640px': '100vw', '90000px': '800px' }"
    class="c-contact-panel p-4"
    @keydown.esc.stop="hideOverlayPanel"
  >
    <ContactList
      :contact="props.contact"
      :supplierContacts="props.supplierContacts"
      @selectedContact="selectedContact"
    />
  </Popover>
</template>

<script setup lang="ts">
import { ref } from "vue";
import ContactList from "./ContactList.vue";
import { PurchaseOrderContact } from "@/models/purchase-order/PurchaseOrderContact";
import { SupplierContact } from "@/models/supplier/SupplierContact";

const contactPanelRef = ref();

const props = defineProps<{
  contact: PurchaseOrderContact;
  supplierContacts: SupplierContact[];
}>();

const emit = defineEmits<{ (e: "contactSelected", value: SupplierContact): void }>();

const toggle = (event: Event) => contactPanelRef.value.toggle(event);
const show = (event: Event) => contactPanelRef.value.show(event);
const hideOverlayPanel = () => contactPanelRef.value.hide();

defineExpose({ toggle: toggle, show: show });

const selectedContact = (contact: SupplierContact) => {
  contactPanelRef.value.hide();
  emit("contactSelected", contact);
};
</script>

<style scoped lang="scss">
//TODO: KH
.c-contact-panel .p-overlaypanel-content {
  height: auto;
}
</style>
