import { useAuth } from "@cumulus/event-bus";
import { PurchaseOrderSearchRequest } from "../../models/search/purchase-order/PurchaseOrderSearchRequest";
import { searchApi } from "./SearchApi";
import { PurchaseOrderSearchResponse } from "../../models/search/purchase-order/PurchaseOrderSearchResponse";
import { SupplierSearchRequest } from "../../models/search/supplier/SupplierSearchRequest";
import { SupplierSearchResponse } from "../../models/search/supplier/SupplierSearchResponse";
import { ProductSearchRequest } from "../../models/search/product/ProductSearchRequest";
import { useErrorHandler } from "../ErrorHandler";
import { ProductSearchResponse } from "../../models/search/product/ProductSearchResponse";
import isEqual from "lodash.isequal";

export function useSearch() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  let purchaseOrderSearchRequestCache: PurchaseOrderSearchRequest | null = null;
  let purchaseOrderSearchResponseCache: PurchaseOrderSearchResponse | null = null;

  const clearPurchaseOrderSearchCache = () => {
    purchaseOrderSearchRequestCache = null;
    purchaseOrderSearchResponseCache = null;
  };

  const purchaseOrderSearch = async (
    request: PurchaseOrderSearchRequest,
    clearCache = false
  ): Promise<PurchaseOrderSearchResponse> => {
    try {
      const authHeaders = await getAuthHeaders();

      if (clearCache) {
        clearPurchaseOrderSearchCache();
      }

      if (isEqual(request, purchaseOrderSearchRequestCache)) {
        return purchaseOrderSearchResponseCache as PurchaseOrderSearchResponse;
      }

      const result = await searchApi.purchaseOrderSearch(authHeaders, request);
      purchaseOrderSearchRequestCache = request;
      purchaseOrderSearchResponseCache = result;
      return result;
    } catch (error) {
      await handleError(error);
      return new PurchaseOrderSearchResponse();
    }
  };

  let supplierSearchRequestCache: SupplierSearchRequest | null = null;
  let supplierSearchResponseCache: SupplierSearchResponse | null = null;

  const supplierSearch = async (
    request: SupplierSearchRequest,
    clearCache = false
  ): Promise<SupplierSearchResponse> => {
    try {
      const authHeaders = await getAuthHeaders();

      if (clearCache) {
        supplierSearchRequestCache = null;
        supplierSearchResponseCache = null;
      }

      if (isEqual(request, supplierSearchRequestCache)) {
        return supplierSearchResponseCache as SupplierSearchResponse;
      }

      const result = await searchApi.supplierSearch(authHeaders, request);
      supplierSearchRequestCache = request;
      supplierSearchResponseCache = result;
      return result;
    } catch (error) {
      await handleError(error);
      return new SupplierSearchResponse();
    }
  };

  let productSearchRequestCache: ProductSearchRequest | null = null;
  let productSearchResponseCache: ProductSearchResponse | null = null;

  const productSearch = async (request: ProductSearchRequest, clearCache = false): Promise<ProductSearchResponse> => {
    try {
      const authHeaders = await getAuthHeaders();

      if (clearCache) {
        productSearchRequestCache = null;
        supplierSearchResponseCache = null;
      }

      if (isEqual(request, productSearchRequestCache)) {
        return productSearchResponseCache as ProductSearchResponse;
      }

      const result = await searchApi.productSearch(authHeaders, request);
      productSearchRequestCache = request;
      productSearchResponseCache = result;
      return result;
    } catch (error) {
      await handleError(error);
      return new ProductSearchResponse();
    }
  };

  return { purchaseOrderSearch, supplierSearch, productSearch, clearPurchaseOrderSearchCache };
}
