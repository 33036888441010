import { PurchaseOrderSupplier } from "./PurchaseOrderSupplier";
import { PurchaseOrderLine } from "./PurchaseOrderLine";
import { PurchaseOrderDelivery } from "./PurchaseOrderDelivery";
import { NIL as emptyUuid } from "uuid";
import { PurchaseOrderFreightMethod } from "./PurchaseOrderFreightMethod";
import { PurchaseOrderPaymentTerm } from "./PurchaseOrderPaymentTerm";
import { PurchaseOrderStatus } from "./PurchaseOrderStatus";

export class PurchaseOrder {
  id = emptyUuid;
  purchaseOrderNumber = 0;
  supplier = new PurchaseOrderSupplier();
  delivery = new PurchaseOrderDelivery();
  purchaseOrderLines: PurchaseOrderLine[] = [];
  purchaseOrderReference = "";
  comment = "";
  paymentTerm = new PurchaseOrderPaymentTerm(emptyUuid, "", 0);
  freightMethod = new PurchaseOrderFreightMethod();
  warehouseId = "";
  purchaseOrderStatus = PurchaseOrderStatus.Open;
  registered = "";
  registeredByEmployeeName = "";
  registeredByEmployeeInitials = "";
  registeredByEmployeeEmail = "";
  sumTotalLines = 0;
  totalSum = 0;
  currencyIso = "";
  shippingPrice = 0;
  inGoodsReception = false;
}
