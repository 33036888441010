import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";

export class PurchaseOrderSearchFilters {
  purchaseOrderStatuses: PurchaseOrderStatus[] = [];
  fromDate: string | null = null;
  toDate: string | null = null;
  productNumbers: string[] = [];
  supplierNumbers: string[] = [];
  registeredBy: string[] = [];
}
