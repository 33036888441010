import { httpClient, type AuthHeaders } from "@cumulus/http";
import { ReceivedGoods } from "../../models/purchase-order/ReceivedGoods";
import { GoodsReception } from "../../models/goods-reception/GoodsReception";

class GoodsReceptionApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_PRODUCT != "undefined" && import.meta.env.VITE_APP_API_PRODUCT
        ? import.meta.env.VITE_APP_API_PRODUCT + "/goods-receptions"
        : `${import.meta.env.VITE_APP_API_URL as string}/goods-receptions`;
  }

  public async getReceivedGoods(authHeaders: AuthHeaders): Promise<ReceivedGoods[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/received-goods")
      .then((response) => response.data);
  }

  public async getGoodsReceptionsForPurchaseOrder(authHeaders: AuthHeaders, id: string): Promise<GoodsReception[]> {
    return await httpClient(this.uri, authHeaders)
      .get("", { params: { purchaseOrderId: id } })
      .then((response) => response.data);
  }
}

const goodsReceptionApi = new GoodsReceptionApi();
export { goodsReceptionApi };
