import { type Incoterms } from "../../models/incoterms/Incoterms";

class IncotermsApi {
  public getAll(): Promise<Incoterms[]> {
    //TODO: Remove MOCK code
    const promise = new Promise<Incoterms[]>((resolve) => {
      const response = [
        { code: "EXW", description: "Ex. Works" },
        { code: "FCA", description: "Free carrier" },
        { code: "CPT", description: "Carriage paid to" },
        { code: "CIP", description: "Carriage and incurance paid to" },
        { code: "DAP", description: "Delivered at place" },
        { code: "DPU", description: "Delivered at place unloaded" },
        { code: "DDP", description: "Delivered duty paid" },
        { code: "FAS", description: "Free alongside ship" },
        { code: "FOB", description: "Free on board" },
        { code: "CFR", description: "Cost and freight" },
        { code: "CIF", description: "Cost insurance and freight" },
      ] as Incoterms[];

      resolve(response);
    });

    return promise;
    //TODO: END MOCK code!
  }
}

const incotermsApi = new IncotermsApi();
export { incotermsApi };
