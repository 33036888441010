import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";
import { computed, type ComputedRef, ref } from "vue";
import { PurchaseOrderLine } from "@/models/purchase-order/PurchaseOrderLine";
import {
  type DataTableRowDoubleClickEvent,
  type DataTableRowSelectEvent,
  type DataTableRowUnselectEvent,
} from "primevue";
import { useConfirm } from "primevue/useconfirm";
import { useI18n } from "vue-i18n";

export function usePurchaseOrderLinesFunctions(
  selectedRowIndex: { value: number },
  resetRowValues: (index: number) => void,
  setFocusedRow: (index: number) => void,
  openProductInfoDialog: (productId: string) => void,
  selectRow: (index: number) => void,
  deletePurchaseOrderLine: (purchaseOrderLine: PurchaseOrderLine) => void,
  purchaseOrderLinesComputed: ComputedRef<PurchaseOrderLine[]>,
  purchaseOrderStatus: PurchaseOrderStatus,
  selectedRow: { value: PurchaseOrderLine },
  previouslyFocusedRow: { value: HTMLTableRowElement | null },
  editingRowIndex: { value: number | null },
  selectedRows: { value: PurchaseOrderLine[] },
  selectedLines: PurchaseOrderLine[],
  selectedLinesDialogVisible: { value: boolean },
) {
  const tablePurchaseOrderLines = ref();
  const toast = useCumulusToast(useToast());
  const confirm = useConfirm();
  const { t } = useI18n();

  const handleKeyPress = (event: KeyboardEvent) => {
    let isRowFocused = false;

    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      const focusedElement = document.activeElement;
      const rows = tablePurchaseOrderLines.value?.$el.querySelectorAll("tbody tr");
      if (rows) {
        rows.forEach((row: HTMLTableRowElement, index: number) => {
          if (row === focusedElement) {
            selectedRowIndex.value = index;
            setFocusedRow(index);
          }
        });
      }
    }

    if (event.key === "Escape") {
      const focusedElement = document.activeElement;
      const rows = tablePurchaseOrderLines.value?.$el.querySelectorAll("tbody tr");

      if (rows) {
        rows.forEach((row: HTMLTableRowElement, index: number) => {
          if (row === focusedElement) {
            const containsInput = row.querySelector("input:not([type='checkbox'])") !== null;
            if (!containsInput) {
              selectedRowIndex.value = index;
              setFocusedRow(index);
              resetRowValues(index);
            }
          }
        });
      }
      event.stopPropagation();
    }

    const orderLine = purchaseOrderLinesComputed.value[selectedRowIndex.value];
    if (event.key.toLowerCase() === "p") {
      if (orderLine) {
        openProductInfoDialog(orderLine.product.id);
      }
    } else if (event.key === "Delete" && purchaseOrderStatus === PurchaseOrderStatus.Open) {
      const focusedElement = document.activeElement;
      const rows = tablePurchaseOrderLines.value?.$el.querySelectorAll("tbody tr");
      if (rows) {
        rows.forEach((row: HTMLTableRowElement) => {
          if (row === focusedElement && orderLine) {
            onConfirmDelete(event, orderLine);
            isRowFocused = true;
          }
        });
      }
    }
    if (
      !isRowFocused &&
      (event.key.toLowerCase() === "p" || event.key === "Delete") &&
      purchaseOrderStatus === PurchaseOrderStatus.Open
    ) {
      toast.add({
        severity: "warn",
        life: 2000,
        detail: "select a row to perform this action",
      });
    }
  };

  const onFocusFirstPurchaseOrderLine = () => {
    if (purchaseOrderLinesComputed.value.length > 0) {
      const rows = tablePurchaseOrderLines.value.$el.querySelectorAll("tbody tr");
      for (const row of rows) {
        if (!row.classList.contains("c-line-done")) {
          row.focus();
          previouslyFocusedRow.value = row;
          const rowIndex = Array.from(rows).indexOf(row);
          selectedRow.value.product.id = purchaseOrderLinesComputed.value[rowIndex].product.id;
          break;
        }
      }
    }
  };

  const stopEditRow = (index: number) => {
    editingRowIndex.value = null;

    const rows = tablePurchaseOrderLines.value?.$el.querySelectorAll("tbody tr");
    if (tablePurchaseOrderLines.value && index > -1) {
      const row = rows[index];
      row.focus();
      document.addEventListener(
        "keydown",
        (event) => {
          if (event.key === "Enter") {
            const nextRow = rows[index + 1];
            if (nextRow) {
              nextRow.focus();
              selectedRowIndex.value = index + 1;
              openFieldsOnRow(index + 1);
            }
          }
        },
        { once: true },
      );
    }
  };

  const openFieldsOnRow = (index: number) => {
    previouslyFocusedRow.value = tablePurchaseOrderLines.value.$el.querySelector(`tbody tr:nth-child(${index + 1})`);
    editingRowIndex.value = index;
  };

  const onSelectOrderLine = (event: DataTableRowDoubleClickEvent) => {
    if (tablePurchaseOrderLines.value && event.index > -1) {
      const row = tablePurchaseOrderLines.value.$el.querySelectorAll("tbody tr")[event.index];

      const inputElement = row?.querySelector("input");
      if (inputElement && inputElement.type !== "checkbox") {
        stopEditRow(event.index);
      } else {
        selectRow(event.index);
      }
    }
  };

  const checkedAllRows = computed<boolean>({
    get: () =>
      selectedRows.value.length === purchaseOrderLinesComputed.value.filter((row) => row.openQuantity > 0).length,
    set: (value) => {
      if (!value) {
        selectedRows.value = [];
      } else {
        selectedRows.value = purchaseOrderLinesComputed.value.filter((row) => row.openQuantity > 0);
        selectedRows.value.forEach((_, index) => {
          stopEditRow(index);
        });
      }
    },
  });

  const onRowSelected = (event: DataTableRowSelectEvent) => {
    if (editingRowIndex.value === event.index) return;

    editingRowIndex.value = null;
    if (event.originalEvent != null) {
      if (!(event.originalEvent instanceof KeyboardEvent)) {
        return;
      }
      if (event.originalEvent.key === "Enter" && event.data.openQuantity != 0) {
        selectRow(event.index);
      }
    }
  };

  const onRowUnselect = (event: DataTableRowUnselectEvent) => {
    if (event.originalEvent != null) {
      if (event.originalEvent instanceof KeyboardEvent && event.originalEvent.key === "Enter") {
        if (selectedLines.length > 1) {
          selectedLinesDialogVisible.value = true;
        } else {
          editingRowIndex.value = event.index;
        }
      }
    }
  };

  const onConfirmDelete = (event: Event, purchaseOrderLine: PurchaseOrderLine) => {
    const targetElement = event.target as HTMLElement;

    confirm.require({
      message: t("common.delete-confirm", { posNr: purchaseOrderLine.positionNumber }),
      header: t("common.confirm"),
      icon: "pi pi-exclamation-triangle",
      position: "center",
      rejectProps: {
        label: t("common.no"),
        severity: "secondary",
        outlined: true,
      },
      acceptProps: {
        label: t("common.yes"),
      },
      accept: async () => {
        deletePurchaseOrderLine(purchaseOrderLine);
      },
      reject: async () => {
        if (document.body.contains(targetElement)) {
          targetElement.focus();
        } else if (previouslyFocusedRow.value) {
          previouslyFocusedRow.value.focus();
        }
      },
    });
  };

  return {
    handleKeyPress,
    tablePurchaseOrderLines,
    onFocusFirstPurchaseOrderLine,
    stopEditRow,
    onSelectOrderLine,
    checkedAllRows,
    onRowSelected,
    onRowUnselect,
    onConfirmDelete,
  };
}
