export class GoodsReceptionHistory {
  receivedQuantity = 0;
  batchNumber = 0;
  receivedDate = "";
  initials = "";
  reference = "";
  purchasePrice = 0;
  goodsReceptionNumber = 0;
  receivedCost = 0;
  currencyIso = "";
}
