<template>
  <div class="mt-5 ml-6 border-b-2 border-slate-300 max-w-xl">
    <FloatLabel variant="on">
      <Select
        v-model="comment"
        :options="comment ? [comment] : []"
        class="border-none rounded-none shadow-none"
        :class="{ 'p-disabled': !allowEdit }"
        :disabled="!allowEdit"
        maxlength="15000"
        data-testId="purchase-comment"
        ref="commentInputRef"
        id="purchase-comment"
        :pt:overlay:class="'hidden'"
        :fluid="true"
        @click="showCommentPanel"
      />
      <label for="purchase-comment">{{ t("purchase.comment.label") }}</label>
    </FloatLabel>
  </div>
  <CommentPanel ref="commentPanelRef" v-model:comment="comment" @tabKeyPressed="tabKeyPressed" />
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";
import CommentPanel from "./CommentPanel.vue";

const { t } = useI18n();

const props = defineProps<{
  purchaseOrderStatus?: PurchaseOrderStatus;
  inGoodsReception: boolean;
}>();

const comment = defineModel<string>("comment");
const allowEdit = computed<boolean>(() => {
  if (props.inGoodsReception) return false;
  return (
    !props.purchaseOrderStatus ||
    props.purchaseOrderStatus === PurchaseOrderStatus.Open ||
    props.purchaseOrderStatus === PurchaseOrderStatus.PartiallyReceived
  );
});

const commentPanelRef = ref();
const showCommentPanel = (event: Event) => {
  const key = (event as KeyboardEvent).key;
  if (key === "Shift" || key === "Escape" || key === "Tab") {
    return;
  }

  commentPanelRef.value.toggle(event);
};
const commentInputRef = ref();
const tabKeyPressed = () => commentInputRef.value.$el?.querySelector("button")?.focus();
</script>
