<template>
  <FloatLabelDropdownPanel
    id="supplier-search-input"
    :selectedItemName="$props.supplier?.name ?? ''"
    :label="t('purchase.supplier.search-label')"
    :selectLabel="t('purchase.supplier.search-placeholder')"
    @toggleDropdownPanel="showSupplierPanel"
    dataTestId="supplier-search-input"
    class="c-supplier-dropdown"
    :class="{ 'p-invalid': val.supplier.$error, 'p-disabled': !allowEdit }"
    :setFocus="focusSearchInputComputed"
    :disabled="!allowEdit"
    :isRequired="true"
  />

  <small class="ml-6 p-error" v-if="val.$error" data-testid="supplier-search-input-error">
    {{ val.$errors[0].$message }}
  </small>

  <SupplierPanel ref="supplierPanelRef" :supplier="props.supplier" @supplierSelected="supplierSelected" />
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import SupplierPanel from "./SupplierPanel.vue";
import { Supplier } from "@/models/supplier/Supplier";
import { PurchaseOrderSupplier } from "@/models/purchase-order/PurchaseOrderSupplier";
import useVuelidate from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";
import { PurchaseOrderLine } from "@/models/purchase-order/PurchaseOrderLine";

const { t } = useI18n();

const props = defineProps<{
  focusSearchInput: boolean;
  supplier: PurchaseOrderSupplier;
  purchaseOrderStatus?: PurchaseOrderStatus;
  purchaseOrderLines: PurchaseOrderLine[];
}>();

const emit = defineEmits<{
  (e: "update:focusSearchInput", value: boolean): void;
  (e: "selectedSupplier", value: Supplier): void;
}>();

const focusSearchInputComputed = computed<boolean>({
  get: () => props.focusSearchInput,
  set: (value) => emit("update:focusSearchInput", value),
});

const supplierSelected = (selectedSupplier: Supplier) => {
  focusSearchInputComputed.value = false;
  emit("selectedSupplier", selectedSupplier);
};

const supplierPanelRef = ref();
const showSupplierPanel = (event: Event) => {
  const key = (event as KeyboardEvent).key;
  if (key === "Shift" || key === "Escape" || key === "Tab") {
    return;
  }

  supplierPanelRef.value.toggle(event);
};

const rules = {
  supplier: {
    name: {
      required,
    },
  },
};

const val = useVuelidate(rules, props);

const allowEdit = computed<boolean>(() => {
  return (
    (!props.purchaseOrderStatus || props.purchaseOrderStatus === PurchaseOrderStatus.Open) &&
    props.purchaseOrderLines.length === 0
  );
});
</script>
<style scoped lang="scss">
.c-supplier-dropdown {
  width: 100%;
}
</style>
