import { useAuth } from "@cumulus/event-bus";
import { ref } from "vue";
import { PurchaseOrderLineInfo } from "@/models/purchase-order/PurchaseOrderLineInfo";
import { ProductOpenQuantity } from "@/models/purchase-order/ProductOpenQuantity";
import { useErrorHandler } from "../ErrorHandler";
import { purchaseOrderApi } from "@/api/purchase-order/PurchaseOrderApi";
import isEqual from "lodash.isequal";

export function usePurchaseOrderService() {
  const loading = ref(false);
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  let productIdRequestCache: string | null = null;
  let purchaseOrderLineInfoCache: PurchaseOrderLineInfo[] | null = null;

  const getLatestOrdersForProduct = async (productId: string, clearCache = false): Promise<PurchaseOrderLineInfo[]> => {
    try {
      loading.value = true;
      const authHeaders = await getAuthHeaders();

      if (clearCache) {
        productIdRequestCache = null;
        purchaseOrderLineInfoCache = null;
      }

      if (isEqual(productId, productIdRequestCache)) {
        return purchaseOrderLineInfoCache as PurchaseOrderLineInfo[];
      }

      const result = await purchaseOrderApi.getLatestOrdersForProduct(authHeaders, productId);
      productIdRequestCache = productId;
      purchaseOrderLineInfoCache = result;
      return result;
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      loading.value = false;
    }
  };

  let productIdsRequestCache: string[] | null = null;
  let productOpenQuantityResponseCache: ProductOpenQuantity[] | null = null;

  const getOpenQuantitiesForProducts = async (
    productIds: string[],
    clearCache = false
  ): Promise<ProductOpenQuantity[]> => {
    try {
      loading.value = true;
      const authHeaders = await getAuthHeaders();

      if (clearCache) {
        productIdsRequestCache = null;
        productOpenQuantityResponseCache = null;
      }

      if (isEqual(productIds, productIdsRequestCache)) {
        return productOpenQuantityResponseCache as ProductOpenQuantity[];
      }

      const result = await purchaseOrderApi.getOpenQuantitiesForProducts(authHeaders, productIds);
      productIdsRequestCache = productIds;
      productOpenQuantityResponseCache = result;
      return result;
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      loading.value = false;
    }
  };

  return { getLatestOrdersForProduct, getOpenQuantitiesForProducts, loading };
}
