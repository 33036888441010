<template>
  <Dialog
    v-model:visible="visible"
    :header="t('purchase.address.dialog-header')"
    :modal="true"
    :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
    class="c-delivery-dialog"
    @keydown.escape.stop="visible = false"
  >
    <div class="c-delivery-address-dialog">
      <div class="flex-wrap col-span-12 md:col-span-12 lg:col-span-12">
        <div class="flex flex-col col-span-12 px-1">
          <FloatLabel variant="on">
            <InputText
              v-model="delivery.name"
              id="delivery-name"
              data-testid="edit-delivery-name"
              type="text"
              class="inputfield w-full"
              :class="{ 'p-invalid': val.name.$error }"
            />
            <label for="delivery-name" class="font-bold">{{ t("purchase.name") }}</label></FloatLabel
          >
          <small class="p-error" v-if="val.name.$error" data-testid="delivery-name-error">
            {{ val.name.$errors[0].$message }}
          </small>
        </div>
        <div class="flex flex-col col-span-12 px-1 mt-6">
          <FloatLabel variant="on">
            <Textarea
              id="delivery-address-lines"
              v-model="addressLinesComputed"
              class="inputfield w-full"
              :placeholder="t('placeholder.type', { property: t('purchase.address.lines').toLowerCase() })"
              :class="{ 'p-invalid': val.address.addressLines.$error }"
              data-testid="edit-delivery-address-lines"
            />
            <label for="delivery-address-lines" class="font-bold">{{ t("purchase.address.lines") }}</label></FloatLabel
          >
          <small class="p-error" v-if="val.address.addressLines.$error" data-testid="delivery-address-lines-error">
            {{ val.address.addressLines.$errors[0].$message }}
          </small>
        </div>
        <div class="flex flex-col col-span-12 px-1 mt-4">
          <FloatLabel variant="on">
            <Select
              id="address-country"
              v-model="delivery.address.countryIso"
              :options="countries"
              data-testid="delivery-country"
              optionLabel="name"
              optionValue="iso"
              class="inputfield w-full"
              :class="{ 'p-invalid': val.address.countryIso.$error }"
              :placeholder="t('placeholder.select', { property: t('purchase.address.country').toLowerCase() })"
            />
            <label for="address-country" class="font-bold">{{ t("purchase.address.country") }}</label></FloatLabel
          >
          <small class="p-error" v-if="val.address.countryIso.$error" id="address-country-error">{{
            val.address.countryIso.$errors[0].$message
          }}</small>
        </div>
        <div class="flex flex-col col-span-12 px-1 mt-6">
          <div class="col p-0">
            <div class="formgrid grid grid-cols-12 gap-4">
              <div class="field col-span-12 md:col-span-4">
                <FloatLabel variant="on">
                  <InputText
                    v-model="delivery.address.postalCode"
                    data-testid="edit-delivery-postal-code"
                    maxlength="4"
                    type="text"
                    class="inputfield w-full"
                    :class="{ 'p-invalid': val.address.postalCode.$error }"
                    :placeholder="t('placeholder.type', { property: t('purchase.address.postal-code').toLowerCase() })"
                    @change="onPostalCodeChange(($event.target as HTMLInputElement).value)"
                  />
                  <label for="edit-delivery-postal-co" class="font-bold">{{ t("purchase.address.postal-code") }}</label>
                </FloatLabel>
                <small
                  class="p-error"
                  v-if="val.address.postalCode.$error"
                  data-testid="delivery-address-postal-code-error"
                  >{{ val.address.postalCode.$errors[0].$message }}</small
                >
              </div>
              <div class="field col-span-12 md:col-span-8">
                <FloatLabel variant="on">
                  <InputText
                    data-testid="delivery-address-city"
                    type="text"
                    v-model="delivery.address.city"
                    class="inputfield w-full"
                    :class="{ 'p-invalid': val.address.city.$error }"
                    :placeholder="t('placeholder.type', { property: t('purchase.address.city').toLowerCase() })"
                  />
                  <label for="delivery-address-city" class="font-bold">{{ t("purchase.address.city") }} </label>
                </FloatLabel>
                <small class="p-error" v-if="val.address.city.$error" data-testid="delivery-address-city-error">{{
                  val.address.city.$errors[0].$message
                }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex flex-row-reverse justify-between mt-4">
        <div class="flex items-center justify-center">
          <Button
            :label="t(`common.cancel`)"
            data-testid="cancel-btn"
            @click="visible = false"
            class="c-dialog-default-button mr-4"
            severity="cancel"
            text
            icon="pi pi-times"
          />
          <Button
            :label="t('common.save')"
            data-testid="save-delivery-address"
            @click="onSave"
            class="c-dialog-default-button c-dialog-success-button"
            icon="pi pi-check"
          />
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { required } from "@/locales/i18n-validators";
import { helpers } from "@vuelidate/validators";
import cloneDeep from "lodash.clonedeep";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { type Country } from "@/models/country/Country";
import { useCountry } from "@/api/country/CountryService";
import { PurchaseOrderDelivery } from "@/models/purchase-order/PurchaseOrderDelivery";

const { t } = useI18n();

const props = defineProps<{
  showDialog: boolean;
  delivery: PurchaseOrderDelivery;
  countries: Country[];
}>();

const emit = defineEmits<{
  (e: "updateDeliveryInformation", value: PurchaseOrderDelivery): void;
  (e: "update:showDialog", value: boolean): void;
}>();

const visible = computed<boolean>({
  get: () => props.showDialog,
  set: (value) => emit("update:showDialog", value),
});

const { getCity } = useCountry();
const delivery = ref<PurchaseOrderDelivery>(props.delivery ? cloneDeep(props.delivery) : new PurchaseOrderDelivery());

const addressLinesComputed = computed<string>({
  get: () => {
    return delivery.value.address.addressLines.join("\n");
  },
  set: (val) => {
    delivery.value.address.addressLines = val.toString().replace(/\r\n/g, "\n").split("\n");
  },
});

const onSave = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    return;
  }
  emit("updateDeliveryInformation", delivery.value);
  visible.value = false;
};

const onPostalCodeChange = async (code: string) => {
  if (code.trim() === "") {
    return;
  }

  const response = await getCity(code);

  delivery.value.address.city = response[0].city;
};

const rules = {
  name: {
    required,
  },
  address: {
    addressLines: {
      required,
      $each: helpers.withMessage(t("validations.required"), (value: string[]) => {
        return value !== undefined && value[0].length > 1;
      }),
    },
    postalCode: {
      required,
    },
    city: {
      required,
    },
    countryIso: {
      required,
    },
  },
};

const val = useVuelidate(rules, delivery.value);
</script>

<style lang="scss">
.c-delivery-address-dialog {
  padding: 0 1.5rem;
  border: none;
}

.c-delivery-dialog {
  width: 25vw;
}
</style>
