import { PurchaseOrderContact } from "./PurchaseOrderContact";
import { Supplier } from "@/models/supplier/Supplier";
import { SupplierContact } from "@/models/supplier/SupplierContact";
import { Address } from "./Address";
import { Incoterm } from "@/models/supplier/Incoterm";

export class PurchaseOrderSupplier {
  id = "";
  supplierNumber = "";
  address = new Address();
  email = "";
  name = "";
  phoneNumber = "";
  documentLanguage = "";
  incoterms = new Incoterm();
  contact = new PurchaseOrderContact();

  public static createFromSupplier = (supplier: Supplier): PurchaseOrderSupplier => {
    const purchaseOrderSupplier = new PurchaseOrderSupplier();
    purchaseOrderSupplier.id = supplier.id;
    purchaseOrderSupplier.supplierNumber = supplier.supplierNumber;
    purchaseOrderSupplier.address = supplier.companyAddress;
    purchaseOrderSupplier.name = supplier.name;
    purchaseOrderSupplier.phoneNumber = supplier.phoneNumber;
    purchaseOrderSupplier.email = supplier.email;
    purchaseOrderSupplier.incoterms = supplier.incoterms;
    purchaseOrderSupplier.documentLanguage = supplier.documentLanguage;
    purchaseOrderSupplier.contact = PurchaseOrderSupplier.createPurchaseOrderContact(supplier.contacts);
    return purchaseOrderSupplier;
  };

  private static createPurchaseOrderContact = (supplierContacts: SupplierContact[]): PurchaseOrderContact => {
    const defaultContact = supplierContacts.find((contact) => contact.isDefaultContact);
    if (defaultContact) {
      return PurchaseOrderContact.createFromSupplierContact(defaultContact);
    }
    return new PurchaseOrderContact();
  };
}
