import { useAuth } from "@cumulus/event-bus";
import { supplierApi } from "./SupplierApi";
import { Supplier } from "../../models/supplier/Supplier";
import { useErrorHandler } from "../ErrorHandler";

export function useSupplier() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getSupplier = async (id: string): Promise<Supplier> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await supplierApi.get(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getSuppliersByIds = async (ids: string[]): Promise<Supplier[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await supplierApi.getSuppliersByIds(authHeaders, ids);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { getSupplier, getSuppliersByIds };
}
