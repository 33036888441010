<template>
  <div class="flex justify-between flex-wrap items-center">
    <div>
      <span class="c-description">{{ t("purchase.purchase-number.label") }}: </span>
      <span data-testid="purchase-order-number">
        {{ purchaseOrderNumber ?? t("purchase.purchase-number.assigned") }}</span
      >
    </div>
    <div v-if="registered">
      <span class="c-description">{{ t("purchase.created") }}: </span>
      <span data-testid="purchase-order-registration-date"> {{ d(registered, "long") }}</span>
    </div>
    <div>
      <span class="c-description">{{ t("purchase.by") }}: </span>
      <span data-testid="purchase-order-employee">{{ registeredBy }}</span>
    </div>
    <div v-if="purchaseOrderStatus">
      <Tag
        class="mr-2"
        data-testid="purchase-order-status"
        :value="t(`purchase.status.${purchaseOrderStatus.toLowerCase()}`)"
        :severity="severityComputed"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";

const { t, d } = useI18n();
const props = defineProps<{
  registeredBy?: string;
  registered?: string;
  purchaseOrderNumber?: string;
  purchaseOrderStatus?: string;
}>();

const severityComputed = computed<string>(() => {
  switch (props.purchaseOrderStatus) {
    case PurchaseOrderStatus.Received:
      return "success";
    case PurchaseOrderStatus.Open:
      return "info";
    case PurchaseOrderStatus.PartiallyReceived:
      return "warning";
    default:
      return "";
  }
});
</script>
<style lang="scss" scoped>
.c-description {
  font-weight: 500;
  color: var(--text-color-normal);
}
</style>
