import { PurchaseOrderDelivery } from "./PurchaseOrderDelivery";
import { PurchaseOrderSupplier } from "./PurchaseOrderSupplier";
import { PurchaseOrderLine } from "./PurchaseOrderLine";
import { NIL as emptyUuid } from "uuid";
import { PurchaseOrderFreightMethod } from "./PurchaseOrderFreightMethod";
import { PurchaseOrderPaymentTerm } from "./PurchaseOrderPaymentTerm";

export class NewPurchaseOrder {
  supplier = new PurchaseOrderSupplier();
  delivery = new PurchaseOrderDelivery();
  purchaseOrderLines: PurchaseOrderLine[] = [];
  purchaseOrderReference = "";
  registeredByEmployeeName = "";
  registeredByEmployeeInitials = "";
  registeredByEmployeeEmail = "";
  comment = "";
  paymentTerm = new PurchaseOrderPaymentTerm(emptyUuid, "", 0);
  freightMethod = new PurchaseOrderFreightMethod();
  currencyIso = "";
  shippingPrice = 0;
  warehouseId = "";
}
