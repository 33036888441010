import { PurchaseOrderDelivery } from "@/models/purchase-order/PurchaseOrderDelivery";
import { PurchaseOrderFreightMethod } from "@/models/purchase-order/PurchaseOrderFreightMethod";
import { PurchaseOrderLine } from "@/models/purchase-order/PurchaseOrderLine";
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";
import { PurchaseOrderSupplier } from "@/models/purchase-order/PurchaseOrderSupplier";

export class SearchPurchaseOrder {
  id = "";
  purchaseOrderNumber = 0;
  purchaseOrderStatus: PurchaseOrderStatus = PurchaseOrderStatus.Open;
  supplier = new PurchaseOrderSupplier();
  delivery = new PurchaseOrderDelivery();
  freightMethod = new PurchaseOrderFreightMethod();
  purchaseOrderReference = "";
  comment = "";
  purchaseOrderLines: PurchaseOrderLine[] = [];
  quantityOfPurchaseOrderLines = 0;
  registered = "";
  registeredByEmployeeEmail = "";
  registeredByEmployeeInitials = "";
  currencyIso = "";
  sumTotalLines = "";
}
