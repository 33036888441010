import { httpClient, type AuthHeaders } from "@cumulus/http";
import { Employee } from "../../models/employee/Employee";

class EmployeeApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_COMPANY != "undefined" && import.meta.env.VITE_APP_API_COMPANY
        ? `${import.meta.env.VITE_APP_API_COMPANY}/employees`
        : `${import.meta.env.VITE_APP_API_URL as string}/employees`;
  }

  public async getCurrentEmployee(authHeaders: AuthHeaders): Promise<Employee> {
    return await httpClient(this.uri, authHeaders)
      .get("/current")
      .then(({ data }) => data);
  }
}
const employeeApi = new EmployeeApi();
export { employeeApi };
