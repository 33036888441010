import { useErrorHandler } from "../ErrorHandler";
import { incotermsApi } from "./IncotermsApi";
import { type Incoterms } from "../../models/incoterms/Incoterms";

export function useIncoterms() {
  const { handleError } = useErrorHandler();

  const getAllIncoterms = async (): Promise<Incoterms[]> => {
    try {
      return await incotermsApi.getAll();
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { getAllIncoterms };
}
