import { httpClient, type AuthHeaders } from "@cumulus/http";
import { DefaultPrinterInfo } from "../../models/print/DefaultPrinterInfo";
import { Printer } from "../../models/print/Printer";
import { PrintDocumentRequest } from "../../models/print/PrintDocumentRequest";
import { DocumentType } from "../../models/print/DocumentType";

class DocumentApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_DOCUMENT != "undefined" && import.meta.env.VITE_APP_API_DOCUMENT
        ? import.meta.env.VITE_APP_API_DOCUMENT
        : `${import.meta.env.VITE_APP_API_URL as string}`;
  }

  public async getPurchaseOrderUrl(authHeaders: AuthHeaders, purchaseOrderId: string): Promise<string> {
    return await httpClient(this.uri, authHeaders)
      .get(`/documents/${purchaseOrderId}`, { params: { documentType: "PurchaseOrder" } })
      .then(({ data }) => data);
  }

  public async getPrinters(authHeaders: AuthHeaders): Promise<Printer[]> {
    return httpClient(this.uri, authHeaders)
      .get("/printing/printers")
      .then(({ data }) => data);
  }

  public async getDefaultPrinterByDocumentType(
    authHeaders: AuthHeaders,
    documentType: DocumentType,
  ): Promise<DefaultPrinterInfo> {
    return httpClient(this.uri, authHeaders)
      .get(`/printing/default-printers/${documentType}`)
      .then(({ data }) => data);
  }

  public async printDocument(authHeaders: AuthHeaders, request: PrintDocumentRequest): Promise<void> {
    return httpClient(this.uri, authHeaders)
      .put("/printing/print-document", request)
      .then(({ data }) => data);
  }
}

export const documentApi = new DocumentApi();
