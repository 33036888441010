<template>
  <BeforeUnloadBrowserTab :hasUpdates="isPurchaseOrderChanged" />
  <PurchaseHeader
    :isPurchaseOrderChanged="isPurchaseOrderChanged"
    :isSaving="isSaving"
    :allowEditPurchaseOrder="true"
    :inGoodsReception="false"
    :purchaseOrderStatus="PurchaseOrderStatus.Open"
    :editMode="false"
    :unsavedChangesDialogVisible="unsavedChangesDialogVisible"
    @onCancel="purchaseOrderRouteService.onCancel"
    @saveClicked="onSave"
    @stayOnPage="purchaseOrderRouteService.stayOnPage"
    @routeToPurchaseOrderSearch="purchaseOrderRouteService.routeToPurchaseOrderSearch"
  />

  <div class="c-purchase" data-testid="add-purchase">
    <div class="c-overlay" v-if="loading">
      <div class="c-spinner-container">
        <ProgressSpinner />
      </div>
    </div>

    <div class="c-content">
      <div class="c-content-top">
        <div class="c-content-top-left mb-6">
          <Card class="c-card-font-size h-full mx-2 mt-2">
            <template #content>
              <div class="flex flex-wrap">
                <div class="c-col-1 pr-6">
                  <div class="mt-4 mb-6">
                    <SupplierInput
                      :supplier="newPurchaseOrder.supplier"
                      :purchaseOrderLines="newPurchaseOrder.purchaseOrderLines"
                      v-model:focusSearchInput="focusSupplierSearchInput"
                      @selectedSupplier="selectedSupplier"
                    />
                  </div>

                  <div class="mb-6">
                    <ContactInput
                      :contact="newPurchaseOrder.supplier.contact"
                      :supplierContacts="supplierContacts"
                      @selectedContact="selectedContact"
                    />
                  </div>

                  <div class="my-4">
                    <ContactEmailInput v-model:email="newPurchaseOrder.supplier.contact.email" />
                  </div>
                </div>

                <div class="c-col-2">
                  <div class="ml-6">
                    <InformationTabs
                      v-model:delivery="newPurchaseOrder.delivery"
                      :supplier="newPurchaseOrder.supplier"
                      :allowEdit="true"
                    />
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </div>

        <div class="c-content-top-right mb-6">
          <Card class="c-card-font-size h-full m-2">
            <template #content>
              <div class="flex flex-wrap">
                <div class="c-col-1 pr-6 mx-1">
                  <div class="mt-4 mb-6">
                    <PurchaseOrderReference v-model:purchaseOrderReference="newPurchaseOrder.purchaseOrderReference" />
                  </div>

                  <div class="mb-6">
                    <PurchaseWarehouse
                      v-model:warehouseId="newPurchaseOrder.warehouseId"
                      @updatePurchaseOrderDelivery="updatePurchaseOrderDelivery($event)"
                      :purchaseOrderLines="newPurchaseOrder.purchaseOrderLines"
                    />
                  </div>

                  <div class="flex flex-wrap">
                    <div class="c-col-1 -mr-6">
                      <div class="mb-2 mr-2">
                        <FreightMethod
                          v-model:freightMethod="newPurchaseOrder.freightMethod"
                          :defaultFreightMethodId="freightMethodIdFromSupplier"
                        />
                      </div>
                    </div>
                    <div class="c-col-2 -mr-6">
                      <div class="mb-2 mr-6">
                        <ShippingPrice
                          class="px-6"
                          v-model:shippingPrice="newPurchaseOrder.shippingPrice"
                          :currencyIso="newPurchaseOrder.currencyIso"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="c-col-2 -mx-4">
                  <div class="mb-6">
                    <CommentInput v-model:comment="newPurchaseOrder.comment" :inGoodsReception="false" />
                  </div>

                  <div class="mt-4 mb-2 mr-1">
                    <Incoterms v-model:selectedIncoterms="newPurchaseOrder.supplier.incoterms" />
                  </div>

                  <div class="flex flex-wrap">
                    <div class="c-col-1">
                      <div class="mb-2">
                        <PaymentTerms
                          v-model:paymentTerm="newPurchaseOrder.paymentTerm"
                          :paymentTermIdFromSupplier="paymentTermIdFromSupplier"
                        />
                      </div>
                    </div>
                    <div class="c-col-2">
                      <div class="mb-2 mr-8">
                        <PurchaseCurrency v-model:currencyIso="newPurchaseOrder.currencyIso" :currencies="currencies" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </div>
      </div>

      <div class="c-purchase-order-lines-content">
        <Card class="c-card-font-size c-purchase-order-lines-card m-2">
          <template #content>
            <div class="c-purchase-order-tables">
              <Suspense>
                <PurchaseOrderLines
                  v-model:purchaseOrderLines="newPurchaseOrder.purchaseOrderLines"
                  :goodsReceptions="[]"
                  :allowEdit="true"
                  :purchaseOrderStatus="PurchaseOrderStatus.Open"
                  :currencyIso="newPurchaseOrder.currencyIso"
                  :employeeId="employeeId"
                  :selectedLines="selectedLines"
                  @purchaseOrderLineDeleted="deletePurchaseOrderLine"
                  @purchaseOrderPriceUpdated="onPurchaseOrderPriceUpdated"
                  @selectionUpdated="(lines) => (selectedLines = lines)"
                  :initialPurchaseOrderLines="initialPurchaseOrderLines"
                  ref="purchaseLinesRef"
                />
              </Suspense>
              <ProductSearch
                :purchaseOrderLines="newPurchaseOrder.purchaseOrderLines"
                :allowSearch="true"
                :selectedSupplierId="newPurchaseOrder.supplier.id"
                :warehouseId="newPurchaseOrder.warehouseId"
                :currencies="currencies"
                :selectedCurrencyIso="newPurchaseOrder.currencyIso"
                @createNewOrderLine="createNewOrderLine"
                @showSupplierDialog="showSupplierDialog"
                ref="productSearchRef"
              />
            </div>
          </template>
        </Card>
      </div>
    </div>

    <SelectSupplierDialog
      v-if="supplierDialogVisible"
      v-model:showDialog="supplierDialogVisible"
      :supplierIds="supplierIds"
      @supplierSelected="onSupplierSelected"
    />
  </div>

  <PurchaseFooter
    :sumTotalLines="sumTotalLines"
    :totalSum="totalSum"
    :currencyIso="newPurchaseOrder.currencyIso"
    v-model:shippingPrice="newPurchaseOrder.shippingPrice"
    @reCalculatePurchaseOrderTotal="reCalculatePurchaseOrderTotal"
    :allowEdit="true"
  />
</template>

<script setup lang="ts">
import { ref, onMounted, nextTick } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import { ShortcutAction, useShortcut } from "@cumulus/shortcut";
import { BeforeUnloadBrowserTab } from "@cumulus/components";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";

//PurchaseOrder
import { useNewPurchaseOrderStore } from "@/stores/NewPurchaseOrderStore";
import { PurchaseOrderLine } from "@/models/purchase-order/PurchaseOrderLine";
import { PurchaseOrderSupplier } from "@/models/purchase-order/PurchaseOrderSupplier";
import { Address } from "@/models/purchase-order/Address";
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";
import { Warehouse } from "@/models/warehouse/Warehouse";
import { usePurchaseOrderRouteService } from "@/api/purchase-order/PurchaseOrderRouteService";

//Calculations
import { usePurchaseOrderCalculation } from "@/utils/calculation/PurchaseOrderCalculation";
import { CalculationBase } from "@/models/purchase-order/calculations/CalculationBase";

//Supplier
import { Supplier } from "@/models/supplier/Supplier";
import { SupplierContact } from "@/models/supplier/SupplierContact";

//Search
import { SearchProductForPurchaseOrder } from "@/models/search/product/SearchProductForPurchaseOrder";
import { SearchProduct } from "@/models/search/product/SearchProduct";

//currency
import { useCurrencyService } from "@/api/currency/CurrencyService.ts";
import { Currency } from "@/models/currency/Currency";

//Components
import SupplierInput from "@/components/supplier/SupplierInput.vue";
import InformationTabs from "@/components/info-tabs/InformationTabs.vue";
import PurchaseCurrency from "@/components/PurchaseCurrency.vue";
import Incoterms from "@/components/Incoterms.vue";
import PurchaseOrderLines from "@/components/PurchaseOrderLines.vue";
import PurchaseOrderReference from "@/components/PurchaseOrderReference.vue";
import ContactInput from "@/components/contact/ContactInput.vue";
import ContactEmailInput from "@/components/contact/ContactEmailInput.vue";
import PaymentTerms from "@/components/PaymentTerms.vue";
import FreightMethod from "@/components/FreightMethod.vue";
import ShippingPrice from "@/components/ShippingPrice.vue";
import CommentInput from "@/components/comment/CommentInput.vue";
import PurchaseHeader from "@/components/PurchaseOrderHeader.vue";
import PurchaseFooter from "@/components/PurchaseOrderFooter.vue";
import PurchaseWarehouse from "@/components/PurchaseWarehouse.vue";
import ProductSearch from "@/components/ProductSearch.vue";
import SelectSupplierDialog from "@/components/SelectSupplierDialog.vue";

import { useAuth } from "@cumulus/event-bus";

const { getUser } = useAuth();
const val = useValidate();
const { t } = useI18n();
const currencies = ref<Currency[]>([]);
const supplierContacts = ref<SupplierContact[]>([]);
const freightMethodIdFromSupplier = ref<string>("");
const paymentTermIdFromSupplier = ref<string>("");
const productSearchRef = ref();
const focusSupplierSearchInput = ref(true);

const selectedLines = ref<PurchaseOrderLine[]>([]);

const sumTotalLines = ref(0);
const totalSum = ref(0);
const initialPurchaseOrderLines = ref<PurchaseOrderLine[]>([]);

const { getAllCurrencies } = useCurrencyService();

const newPurchaseOrderStore = useNewPurchaseOrderStore();
const { newPurchaseOrder, isPurchaseOrderChanged, isSaving, loading } = storeToRefs(newPurchaseOrderStore);

const purchaseOrderRouteService = usePurchaseOrderRouteService(
  null,
  isPurchaseOrderChanged,
  async (): Promise<void> => {
    newPurchaseOrderStore.clearStore();
  }
);

const { unsavedChangesDialogVisible } = purchaseOrderRouteService;

const { calculateSumLineTotalPrice, calculateSumTotalLines, calculateTotalSum } = usePurchaseOrderCalculation();
const employeeId = ref<string>("");
onMounted(async () => {
  employeeId.value = (await getUser()).getEmployee().id;
  await newPurchaseOrderStore.setEmployee();
  await newPurchaseOrderStore.setDefaultWarehouseAndDelivery();
  await fetchCurrencies();
});

const fetchCurrencies = async () => {
  currencies.value = await getAllCurrencies();
};

const onPurchaseOrderPriceUpdated = (purchaseOrderLine: PurchaseOrderLine, calculationBase: CalculationBase) => {
  const sumLine = calculateSumLineTotalPrice(purchaseOrderLine, calculationBase);
  const line = newPurchaseOrder.value.purchaseOrderLines.find((line) => line.id === purchaseOrderLine.id);
  if (line) {
    line.purchasePrice = purchaseOrderLine.purchasePrice;
    line.sumLine = sumLine;
  }

  sumTotalLines.value = calculateSumTotalLines(newPurchaseOrder.value.purchaseOrderLines);
  totalSum.value = calculateTotalSum(newPurchaseOrder.value.purchaseOrderLines, newPurchaseOrder.value.shippingPrice);
};

const reCalculatePurchaseOrderTotal = () => {
  sumTotalLines.value = calculateSumTotalLines(newPurchaseOrder.value.purchaseOrderLines);
  totalSum.value = calculateTotalSum(newPurchaseOrder.value.purchaseOrderLines, newPurchaseOrder.value.shippingPrice);
};

const updatePurchaseOrderDelivery = (warehouse: Warehouse) => {
  if (warehouse) {
    newPurchaseOrder.value.delivery.name = warehouse.name;
    newPurchaseOrder.value.delivery.address = Address.CreateFromWarehouseAddress(warehouse.address);
  }
};

const toast = useCumulusToast(useToast());

const onSave = async () => {
  //Wait for input onblur functions and calculations to finish
  if (document.activeElement?.localName === "input") {
    (document.activeElement as HTMLElement).blur();
  }
  val.value.$touch();
  unsavedChangesDialogVisible.value = false;

  if (!(await val.value.$validate())) {
    toast.add({
      severity: "warn",
      summary: t("purchase.add.toast.validation.summary"),
      detail: t("purchase.add.toast.validation.detail"),
      closable: true,
    });
    return;
  }

  if (await newPurchaseOrderStore.createOrder()) {
    resetView();
  }
};

const supplierDialogVisible = ref<boolean>(false);
const supplierIds = ref<string[]>([]);
const selectedProductForPurchaseOrder = ref<SearchProductForPurchaseOrder>({} as SearchProductForPurchaseOrder);
const selectedSearchProduct = ref<SearchProduct>({} as SearchProduct);

const showSupplierDialog = (product: SearchProductForPurchaseOrder, searchProduct: SearchProduct) => {
  selectedSearchProduct.value = searchProduct;
  selectedProductForPurchaseOrder.value = product;

  supplierIds.value = product.supplierIds;
  supplierDialogVisible.value = true;
};

const onSupplierSelected = async (supplier: Supplier) => {
  selectedSupplier(supplier);

  const supplierPrice = selectedSearchProduct.value.supplierPrices.find((price) => price.supplierId === supplier.id);
  const [supplierPurchasePrice] = SearchProductForPurchaseOrder.getSupplierCostPriceForSupplier(supplierPrice);

  selectedProductForPurchaseOrder.value.supplierPurchasePrice = supplierPurchasePrice;
};

const createNewOrderLine = async (product: SearchProductForPurchaseOrder, searchProduct: SearchProduct) => {
  selectedSearchProduct.value = searchProduct;
  selectedProductForPurchaseOrder.value = product;

  await addPurchaseOrderLine(product);
  await nextTick();
  productSearchRef.value.selectProductSearchInput();
};

const purchaseLinesRef = ref();
const resetView = async () => {
  val.value.$reset();
  window.scrollTo(0, 0);
  await newPurchaseOrderStore.clearStore();
  sumTotalLines.value = 0;
  totalSum.value = 0;
  paymentTermIdFromSupplier.value = "";
  freightMethodIdFromSupplier.value = "";
  productSearchRef.value.reset();
};

const deletePurchaseOrderLine = (purchaseLine: PurchaseOrderLine) => {
  const index = newPurchaseOrder.value.purchaseOrderLines?.findIndex((item) => item.id === purchaseLine.id);
  if (index !== -1) {
    newPurchaseOrder.value.purchaseOrderLines?.splice(index, 1);
    for (let index = 0; index < newPurchaseOrder.value.purchaseOrderLines.length; index++) {
      newPurchaseOrder.value.purchaseOrderLines[index].positionNumber = index;
    }
  }
};

const addPurchaseOrderLine = async (product: SearchProductForPurchaseOrder) => {
  const purchaseOrderLine = PurchaseOrderLine.Create(product);
  purchaseOrderLine.quantity = product.quantity ?? 1;
  purchaseOrderLine.purchasePrice = product.supplierPurchasePrice ?? 0;
  purchaseOrderLine.shippingDate = product.shippingDate ?? new Date(Date.now()).toDateOnlyString();
  purchaseOrderLine.estimatedArrivalDate = product.estimatedArrivalDate ?? new Date(Date.now()).toDateOnlyString();
  newPurchaseOrder.value.purchaseOrderLines.push(purchaseOrderLine);
  purchaseOrderLine.positionNumber = newPurchaseOrder.value.purchaseOrderLines.length;

  await reCalculatePurchaseOrderTotal();
  await nextTick();
  productSearchRef.value.selectProductSearchInput();
};

useShortcut(ShortcutAction.save, onSave);

const selectedSupplier = (supplier: Supplier) => {
  newPurchaseOrder.value.supplier = PurchaseOrderSupplier.createFromSupplier(supplier);
  supplierContacts.value = supplier.contacts;
  newPurchaseOrder.value.currencyIso = supplier.currency?.name ?? "";

  paymentTermIdFromSupplier.value = supplier.defaultPaymentTermId;
  freightMethodIdFromSupplier.value = supplier.freightMethodId;
};

const selectedContact = (contact: SupplierContact) => {
  newPurchaseOrder.value.supplier.contact = contact;
};
</script>

<style lang="scss" scoped>
.c-purchase {
  margin: var(--default-content-margin);
  margin-top: 0;
  margin-bottom: 0;
}

.c-content-top {
  display: flex;
  flex-wrap: wrap;
}
.c-content-top-left {
  flex: 50%;
}
.c-content-top-right {
  flex: 50%;
}

.c-col-1 {
  flex: 50%;
}
.c-col-2 {
  flex: 50%;
}

@media (max-width: 1024px) {
  .c-content-top-left,
  .c-content-top-right {
    flex: 100%;
  }
}

.c-purchase-order-lines-card {
  margin-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.c-card-font-size {
  font-size: 12.8px;
}

.c-content {
  position: relative;
  transition: all 0.25s;
}

.c-spinner-container {
  position: relative;
  top: 175px;
}
</style>
