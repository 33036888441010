import { PurchaseOrderLine } from "./PurchaseOrderLine";
import { NIL as emptyUuid } from "uuid";

export class PurchaseOrderPartialUpdate {
  id = emptyUuid;
  purchaseLines: PurchaseOrderLine[] = [];

  constructor(id: string, purchaseLines: PurchaseOrderLine[]) {
    this.id = id;
    this.purchaseLines = purchaseLines;
  }
}
