import { type Component } from "vue";
import { type RouteRecordRaw } from "vue-router";

export const pruchaseRoutes = [
  {
    path: "/purchase/add",
    name: "purchase-add",
    component: (): Component => import("@/views/PurchaseOrderAdd.vue"),
    meta: {
      authRequired: true,
      title: "purchase.title.add-purchase",
    },
  },
  {
    path: "/purchase/edit/:id",
    name: "purchase-edit",
    component: (): Component => import("@/views/PurchaseOrderEdit.vue"),
    meta: {
      authRequired: true,
      title: "purchase.title.edit-purchase",
    },
  },
  {
    path: "/purchase/search/:search?",
    name: "purchase-search",
    component: (): Component => import("@/views/PurchaseOrderSearch.vue"),
    meta: {
      authRequired: true,
      title: "purchase.title.search-purchase",
    },
  },
  {
    path: "/purchase/received-goods",
    name: "received-goods",
    component: (): Component => import("@/views/ReceivedGoods.vue"),
    meta: {
      authRequired: true,
      title: "purchase.title.received-goods",
    },
  },
];

export const mainRoutes: RouteRecordRaw[] =
  import.meta.env.VITE_APP_STANDALONE === "true" && !import.meta.env.VITE_APP_TESTS
    ? [
        {
          name: "Pages",
          path: "/",
          component: (): Component => import("./Routes.vue"),
        },
        {
          name: "oauth2-redirect",
          path: "/oauth2-redirect",
          component: (): Component => import("../components/b2c/OAuth2Redirect.vue"),
        },
        {
          name: "Failed",
          path: "/failed",
          component: (): Component => import("../components/b2c/Failed.vue"),
        },
        {
          name: "Logout",
          path: "/logout",
          component: (): Component => import("../components/b2c/OAuth2Signout.vue"),
        },
      ]
    : [];
