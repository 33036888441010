import { httpClient, type AuthHeaders } from "@cumulus/http";
import { type PaymentTerm } from "../../models/payment-term/PaymentTerm";

class PaymentTermApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_FINANCE != "undefined" && import.meta.env.VITE_APP_API_FINANCE
        ? `${import.meta.env.VITE_APP_API_FINANCE}/payment-terms`
        : `${import.meta.env.VITE_APP_API_URL as string}/payment-terms`;
  }

  public async get(authHeaders: AuthHeaders): Promise<PaymentTerm[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/for-suppliers")
      .then(({ data }) => data);
  }
}
const paymentTermApi = new PaymentTermApi();
export { paymentTermApi };
