export const PurchaseOrderListColumns = {
  Registered: "registered",
  RegisteredByEmployeeInitials: "registeredByEmployeeInitials",
  PurchaseOrderNumber: "purchaseOrderNumber",
  SupplierNumber: "supplier.supplierNumber",
  SupplierName: "supplier.name",
  PurchaseOrderReference: "purchaseOrderReference",
  FreightMethodname: "freightMethod.name",
  TotalItems: "totalItems",
  QuantityOfPurchaseOrderLines: "quantityOfPurchaseOrderLines",
  PurchaseOrderStatus: "purchaseOrderStatus",
  PDF: "pdf",
  ColumnChooser: "columnChooser",
} as const;
