import { SupplierContact } from "@/models/supplier/SupplierContact";

export class PurchaseOrderContact {
  firstName = "";
  lastName = "";
  email = "";

  public static createFromSupplierContact = (supplierContact: SupplierContact): PurchaseOrderContact => {
    const contact = new PurchaseOrderContact();
    contact.firstName = supplierContact.firstName;
    contact.lastName = supplierContact.lastName;
    contact.email = supplierContact.email;
    return contact;
  };
}
