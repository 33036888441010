import { WarehouseAddress } from "@/models/warehouse/WarehouseAddress";

export class Address {
  addressLines: string[] = [];
  countryIso = "";
  postalCode = "";
  city = "";

  public static CreateFromWarehouseAddress(warehouseAddress: WarehouseAddress): Address {
    const address = new Address();
    address.addressLines = warehouseAddress.addressLines;
    address.countryIso = warehouseAddress.countryIso;
    address.postalCode = warehouseAddress.postalCode;
    address.city = warehouseAddress.city;
    return address;
  }

  constructor(addressLines?: string[], countryIso?: string, postalCode?: string, city?: string) {
    this.addressLines = addressLines ?? [];
    this.countryIso = countryIso ?? "";
    this.postalCode = postalCode ?? "";
    this.city = city ?? "";
  }
}
