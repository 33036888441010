import { PurchaseOrderLine } from "@/models/purchase-order/PurchaseOrderLine";
import { CalculationBase } from "@/models/purchase-order/calculations/CalculationBase";
import { PriceCalculation } from "./PriceCalculation";

export function usePurchaseOrderCalculation() {
  const decimalPlaces = 2;

  const calculateSumLineTotalPrice = (
    purchaseOrderLine: PurchaseOrderLine,
    calculationBase: CalculationBase
  ): number => {
    switch (calculationBase) {
      case CalculationBase.PurchasePrice: {
        return PriceCalculation.calculateOrderLineTotalPrice(
          purchaseOrderLine.quantity,
          purchaseOrderLine.purchasePrice,
          decimalPlaces
        );
      }
      default:
        throw "Unknown calculation base";
    }
  };

  const calculateSumTotalLines = (purchaseOrderLines: PurchaseOrderLine[]): number => {
    if (purchaseOrderLines.length == 0) {
      return 0;
    }

    return PriceCalculation.calculateTotalPurchaseOrderLines(purchaseOrderLines, decimalPlaces);
  };

  const calculateTotalSum = (purchaseOrderLines: PurchaseOrderLine[], shippingPrice: number): number => {
    if (purchaseOrderLines.length == 0) {
      return 0;
    }

    return PriceCalculation.calculateTotalPurchaseOrderSum(purchaseOrderLines, shippingPrice, decimalPlaces);
  };

  return {
    calculateSumTotalLines,
    calculateTotalSum,
    calculateSumLineTotalPrice,
  };
}
