<template>
  <FloatLabelDropdownPanel
    id="contact-search-input"
    :selectedItemName="contactName"
    :label="t('purchase.contact.label')"
    :selectLabel="t('purchase.contact.label')"
    @toggleDropdownPanel="showContactPanel"
    dataTestId="contact-search-input"
    class="c-contact-dropdown"
    :class="{ 'p-invalid': val.contact.firstName.$error, 'p-disabled': !allowEdit }"
    :disabled="!allowEdit"
    :isRequired="true"
  />

  <small class="ml-6 p-error" v-if="val.contact.$error" data-testid="custom-contact-error">
    {{ val.contact.$errors[0].$message }}
  </small>

  <ContactPanel
    ref="contactPanelRef"
    :contact="props.contact"
    :supplierContacts="props.supplierContacts"
    @contactSelected="contactSelected"
  />
</template>

<script setup lang="ts">
import { ref, computed, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import ContactPanel from "./ContactPanel.vue";
import { PurchaseOrderContact } from "@/models/purchase-order/PurchaseOrderContact";
import { SupplierContact } from "@/models/supplier/SupplierContact";
import { required } from "@/locales/i18n-validators";
import useVuelidate from "@vuelidate/core";
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";

const { t } = useI18n();

const props = defineProps<{
  contact: PurchaseOrderContact;
  supplierContacts: SupplierContact[];
  purchaseOrderStatus?: PurchaseOrderStatus;
}>();

const contactName = computed<string>(() => {
  return (props.contact?.firstName + " " + props.contact?.lastName).trim();
});

const emit = defineEmits<{
  (e: "update:focusSearchInput", value: boolean): void;
  (e: "selectedContact", value: SupplierContact): void;
}>();

const contactSelected = (selectedContact: SupplierContact) => {
  emit("selectedContact", selectedContact);

  nextTick(() => {
    document.getElementById("contact-email")?.focus();
  });
};

const contactPanelRef = ref();
const showContactPanel = (event: Event) => {
  const key = (event as KeyboardEvent).key;
  if (key === "Shift" || key === "Escape" || key === "Tab") {
    return;
  }

  contactPanelRef.value.toggle(event);
};

const rules = {
  contact: {
    firstName: {
      required,
    },
  },
};

const val = useVuelidate(rules, props);

const allowEdit = computed<boolean>(() => {
  return !props.purchaseOrderStatus || props.purchaseOrderStatus === PurchaseOrderStatus.Open;
});
</script>
<style scoped lang="scss">
.c-contact-dropdown {
  width: 100%;
}
</style>
