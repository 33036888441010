import { ProductOpenQuantity } from "@/models/purchase-order/ProductOpenQuantity";
import { ProductAvailability } from "./ProductAvailability";
import { ProductSearchResponse } from "./ProductSearchResponse";
import { SupplierPrice } from "./SupplierPrice";
import { PurchaseOrderLine } from "@/models/purchase-order/PurchaseOrderLine";

export const toDateOnlyString = (d: Date) => {
  let date = d.getDate().toString();
  if (date.length === 1) date = "0" + date;
  let month = (d.getMonth() + 1).toString();
  if (month.length === 1) month = "0" + month;
  const year = d.getFullYear();
  return year + "-" + month + "-" + date;
};

export class SearchProductForPurchaseOrder {
  id = "";
  name = "";
  productNumber = "";
  gtin = "";
  listPrice = 0;
  priceCurrencyIso = "";
  quantityInStockForWarehouse = 0;
  quantityInBackOrderForWarehouse = 0;
  quantityAvailableFromStockForWarehouse = 0;
  quantityInOrderReserveForWarehouse = 0;
  supplierPurchasePrice = 0;
  supplierProductNumber = "";
  sumOfQuantityInOpenPurchaseOrders = 0;
  quantityAvailable = 0;
  quantityInDemand = 0;
  supplierIds: string[] = [];
  supplierCurrencyIso = "";
  quantity = 0;
  shippingDate = toDateOnlyString(new Date(Date.now()));
  estimatedArrivalDate = toDateOnlyString(new Date(Date.now()));

  public static createFromProductSearch(
    productSearchResponse: ProductSearchResponse,
    purchaseOrderProductSummary: ProductOpenQuantity[],
    warehouseId: string | null,
    supplierId: string,
    existingPurchaseOrderLines: PurchaseOrderLine[],
    onlyInDemand: boolean
  ) {
    const searchProductForPurchaseOrder: SearchProductForPurchaseOrder[] = [];
    const isWarehouseSpecified = warehouseId !== "" && warehouseId !== null;
    const isSupplierSpecified = supplierId !== "" && supplierId !== null;

    productSearchResponse.products.forEach((product) => {
      const supplierPrice = isSupplierSpecified
        ? product.supplierPrices.find((x) => x.supplierId === supplierId)
        : product.supplierPrices.find((x) => x.isDefaultSupplier);

      const sumOfQuantityInOpenPurchaseOrders =
        purchaseOrderProductSummary.find((x) => x.id === product.id)?.sumOfQuantityInOpenPurchaseOrders ?? 0;

      const quantityInStockForWarehouse = isWarehouseSpecified
        ? this.getQuantityInStockForWarehouseById(product.availability, warehouseId)
        : this.getTotalQuantityInStock(product.availability);

      const quantityInBackOrderForWarehouse = isWarehouseSpecified
        ? this.getQuantityInBackOrderForWarehouseById(product.availability, warehouseId)
        : this.getTotalOfQuantityInBackOrder(product.availability);

      const quantityAvailableFromStockForWarehouse = isWarehouseSpecified
        ? this.getQuantityAvailableFromStockForWarehouse(product.availability, warehouseId)
        : this.getTotalQuantityAvailableFromStock(product.availability);

      const quantityInOrderReserveForWarehouse = isWarehouseSpecified
        ? this.getQuantityInOrderReserveForWarehouseById(product.availability, warehouseId)
        : this.getTotalQuantityInOrderReserve(product.availability);

      const [supplierPurchasePrice, supplierCurrencyIso] = this.getSupplierCostPriceForSupplier(supplierPrice);

      const quantityAvailable =
        quantityInStockForWarehouse + sumOfQuantityInOpenPurchaseOrders - quantityInOrderReserveForWarehouse;

      const productsAdded = existingPurchaseOrderLines
        .filter((line) => line.product.id === product.id)
        .reduce((accumulator, product) => accumulator - product.quantity, 0);

      const quantityInDemand = quantityAvailable - productsAdded;

      const productForPurchaseOrderItem: SearchProductForPurchaseOrder = {
        id: product.id,
        name: product.name,
        productNumber: product.productNumber,
        gtin: product.gtin,
        listPrice: product.prices[0]?.listPrice ?? 0,
        priceCurrencyIso: product.prices[0]?.currencyIso ?? "",
        quantityInStockForWarehouse: quantityInStockForWarehouse,
        quantityInBackOrderForWarehouse: quantityInBackOrderForWarehouse,
        quantityAvailableFromStockForWarehouse: quantityAvailableFromStockForWarehouse,
        quantityInOrderReserveForWarehouse: quantityInOrderReserveForWarehouse,
        supplierPurchasePrice: supplierPurchasePrice,
        supplierProductNumber: this.getSupplierProductNumberForSupplier(supplierPrice),
        supplierIds: product.supplierPrices.map((x: SupplierPrice) => x.supplierId),
        sumOfQuantityInOpenPurchaseOrders: sumOfQuantityInOpenPurchaseOrders,
        quantityAvailable: quantityAvailable,
        quantityInDemand: quantityInDemand < 0 ? -quantityInDemand : 0,
        supplierCurrencyIso: supplierCurrencyIso,
        quantity: quantityInDemand <= 0 ? -quantityInDemand : 0,
        shippingDate: new Date(Date.now()).toDateOnlyString(),
        estimatedArrivalDate: this.getEstimatedDeliveryDateFromSupplier(supplierPrice),
      };

      // Only show search products that are in demand
      if (onlyInDemand && productForPurchaseOrderItem.quantityInDemand <= 0) {
        return;
      }

      searchProductForPurchaseOrder.push(productForPurchaseOrderItem);
    });

    return searchProductForPurchaseOrder;
  }

  private static getQuantityInStockForWarehouseById(
    availabilities: ProductAvailability[],
    warehouseId: string | null
  ): number {
    return availabilities.find((x) => x.warehouseId === warehouseId)?.quantityInStock ?? 0;
  }

  private static getTotalQuantityInStock(availabilities: ProductAvailability[]): number {
    return availabilities.reduce((total, x) => total + x.quantityInStock, 0);
  }

  private static getQuantityInBackOrderForWarehouseById(
    availabilities: ProductAvailability[],
    warehouseId: string | null
  ): number {
    return availabilities.find((x) => x.warehouseId === warehouseId)?.quantityInBackOrder ?? 0;
  }

  private static getTotalOfQuantityInBackOrder(availabilities: ProductAvailability[]): number {
    return availabilities.reduce((total, x) => total + x.quantityInBackOrder, 0);
  }

  private static getQuantityAvailableFromStockForWarehouse(
    availabilities: ProductAvailability[],
    warehouseId: string | null
  ): number {
    return availabilities.find((x) => x.warehouseId === warehouseId)?.quantityAvailableFromStock ?? 0;
  }

  private static getTotalQuantityAvailableFromStock(availabilities: ProductAvailability[]): number {
    return availabilities.reduce((total, x) => total + x.quantityAvailableFromStock, 0);
  }

  private static getQuantityInOrderReserveForWarehouseById(
    availabilities: ProductAvailability[],
    warehouseId: string | null
  ): number {
    const availability = availabilities.find((x) => x.warehouseId === warehouseId);
    if (!availability) {
      return 0;
    }

    return (
      availability.quantityAllocatedForPicking + availability.quantityInBackOrder + availability.quantityForDelivery
    );
  }

  private static getTotalQuantityInOrderReserve(availabilities: ProductAvailability[]): number {
    return availabilities.reduce(
      (total, x) => total + x.quantityAllocatedForPicking + x.quantityInBackOrder + x.quantityForDelivery,
      0
    );
  }

  public static getSupplierCostPriceForSupplier(supplierPrice: SupplierPrice | undefined): [number, string] {
    return [supplierPrice?.purchasePrice ?? 0, supplierPrice?.currencyIso ?? ""];
  }

  private static getSupplierProductNumberForSupplier(supplierPrice: SupplierPrice | undefined): string {
    return supplierPrice?.productNumber ?? "";
  }

  private static getEstimatedDeliveryDateFromSupplier(supplierPrice: SupplierPrice | undefined): string {
    const days = supplierPrice?.estimatedDeliveryDays ?? 0;
    const date = new Date(Date.now());
    date.setDate(date.getDate() + days);
    return toDateOnlyString(date);
  }
}
