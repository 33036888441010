import { ref, onMounted, onBeforeUnmount, Ref } from "vue";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

export function usePurchaseOrderRouteService(
  purchaseOrderId: Ref<string> | null,
  isPurchaseOrderChanged: Ref<boolean>,
  callbackClearStore: () => void
) {
  const confirmedDiscard = ref(false);
  const previouslyFocusedInput = ref<HTMLInputElement | null>(null);
  const unsavedChangesDialogVisible = ref(false);

  const router = useRouter();
  const { t } = useI18n();

  onMounted(() => {
    document.addEventListener("keydown", handleKeydown);
  });

  onBeforeRouteLeave((_to, _from, next) => {
    if (isPurchaseOrderChanged.value && !confirmedDiscard.value) {
      unsavedChangesDialogVisible.value = true;
      next(false);
    } else {
      next();
    }
  });

  const handleKeydown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      if (unsavedChangesDialogVisible.value) {
        stayOnPage();
      } else onCancel();
    } else if (event.ctrlKey && event.key === "i" && unsavedChangesDialogVisible.value) {
      routeToPurchaseOrderSearch();
    }
  };

  onBeforeUnmount(() => {
    document.removeEventListener("keydown", handleKeydown);
  });

  const onCancel = () => {
    previouslyFocusedInput.value = document.activeElement as HTMLInputElement;

    if (isPurchaseOrderChanged.value === true) {
      unsavedChangesDialogVisible.value = true;
    } else {
      routeToPurchaseOrderSearch();
    }
  };

  const stayOnPage = () => {
    unsavedChangesDialogVisible.value = false;
    if (previouslyFocusedInput.value) {
      previouslyFocusedInput.value.focus();
    }
  };

  const routeToPurchaseOrderSearch = async () => {
    confirmedDiscard.value = true;

    if (window.history.state.back === null || window.history.state.back.indexOf("/purchase/search") === -1) {
      router.push({ name: "purchase-search", query: { search: "" } });
    } else {
      const url = new URL(window.location.origin + window.history.state.back);
      if (purchaseOrderId !== null) {
        url.searchParams.set("focusResult", purchaseOrderId.value);
      }

      router.push({ path: window.history.state.back, query: paramsToObject(url.searchParams) });
    }

    callbackClearStore();
  };

  const paramsToObject = (entries: URLSearchParams) => {
    const result = {} as Record<string, string>;
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  };

  window.addEventListener("beforeunload", (e) => {
    if (isPurchaseOrderChanged.value) {
      e.preventDefault();
      e.returnValue = t("common.unsaved-changes-header");
    }
  });

  return {
    routeToPurchaseOrderSearch,
    onCancel,
    stayOnPage,
    unsavedChangesDialogVisible,
    isPurchaseOrderChanged,
  };
}
