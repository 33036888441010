import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "@/api/ErrorHandler";
import { Currency } from "../../models/currency/Currency";
import { currencyApi } from "./CurrencyApi";

export function useCurrencyService() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getAllCurrencies = async (): Promise<Currency[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await currencyApi.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getAllCurrencies,
  };
}
