import { PurchaseOrderProduct } from "./PurchaseOrderProduct";
import { v4 as uuidv4 } from "uuid";
import { SearchProductForPurchaseOrder } from "@/models/search/product/SearchProductForPurchaseOrder";
import { PurchaseOrderStatus } from "./PurchaseOrderStatus";

export class PurchaseOrderLine {
  id: string = uuidv4();
  positionNumber = 0;
  product = new PurchaseOrderProduct();
  quantity = 0;
  receivedQuantity = 0;
  openQuantity = 0;
  shippingDate = new Date().toDateOnlyString();
  estimatedArrivalDate = new Date().toDateOnlyString();
  confirmed = false;
  purchasePrice = 0;
  sumLine = 0;
  status = PurchaseOrderStatus.PartiallyReceived;

  public static Create = (selectedProduct: SearchProductForPurchaseOrder): PurchaseOrderLine => {
    const purchaseOrderLine = new PurchaseOrderLine();
    purchaseOrderLine.product.supplierProductNumber = selectedProduct.supplierProductNumber;
    purchaseOrderLine.product.id = selectedProduct.id;
    purchaseOrderLine.product.name = selectedProduct.name;
    purchaseOrderLine.product.productNumber = selectedProduct.productNumber;
    purchaseOrderLine.product.gtin = selectedProduct.gtin;
    purchaseOrderLine.purchasePrice = selectedProduct.supplierPurchasePrice;
    purchaseOrderLine.quantity = selectedProduct.quantity ?? 1;
    purchaseOrderLine.openQuantity = purchaseOrderLine.quantity - purchaseOrderLine.receivedQuantity;
    purchaseOrderLine.sumLine = purchaseOrderLine.quantity * purchaseOrderLine.purchasePrice;

    return purchaseOrderLine;
  };
}
