<template>
  <DataTable
    ref="supplierTableRef"
    :value="suppliers"
    scrollHeight="flex"
    dataKey="id"
    selectionMode="single"
    @row-select="onRowSelect"
    :loading="loading"
    :rowHover="true"
    data-testid="supplier-search-list"
    sortField="name"
    :sortOrder="1"
    stripedRows
    autoLayout
    :paginator="true"
    :rows="10"
    class="c-datatable"
  >
    <template #header>
      <span class="p-input-icon-left">
        <i class="pi pi-search absolute mt-2" />
        <InputText
          id="supplierSearchInput"
          ref="searchInputRef"
          v-model="query"
          v-debounce:200="search"
          :placeholder="t('common.search')"
          @focus="selectAllOnFocus"
          @keydown.enter="searchInputEnterKey"
          @keypress.tab="focusFirstSearchResult"
          @keydown.down="focusFirstSearchResult"
          class="w-full pl-8"
          autocomplete="off"
          data-testid="supplier-search-list-input"
        />
      </span>
    </template>

    <template>
      <Column field="supplierNumber" :header="t('purchase.supplier.number')"> </Column>
      <Column field="name" :header="t('purchase.supplier.name')"> </Column>
      <Column field="deliveryAddress.city" :header="t('purchase.supplier.city')"> </Column>
      <Column field="phoneNumber" :header="t('purchase.supplier.phone')"> </Column>
      <Column field="email" :header="t('purchase.supplier.email')"> </Column>
    </template>

    <template #loading>{{ t("common.loading") }}</template>

    <template #empty>
      <div>
        {{
          query.length < 1
            ? t("purchase.supplier.empty-search-query")
            : t("purchase.supplier.no-suppliers-found", { search: query })
        }}
      </div>
    </template>

    <template #footer>
      <div v-if="!loading" class="flex justify-end" style="margin-bottom: -1.2rem">
        {{
          t("purchase.supplier.search-footer-result", {
            searchCount: suppliers.length,
            totalSearchCount: totalHits,
          })
        }}
      </div>
    </template>
  </DataTable>
</template>

<script setup lang="ts">
import { ref, nextTick, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { type DataTableRowSelectEvent } from "primevue/datatable";
import { Supplier } from "@/models/supplier/Supplier";
import { useSearch } from "@/api/search/SearchService";
import { useSupplier } from "@/api/supplier/SupplierService";
import { SearchSupplier } from "@/models/search/supplier/SearchSupplier";
import { SupplierSearchRequest } from "@/models/search/supplier/SupplierSearchRequest";
import { PurchaseOrderSupplier } from "@/models/purchase-order/PurchaseOrderSupplier";

const { supplierSearch } = useSearch();
const { getSupplier } = useSupplier();

const { t } = useI18n();

const props = defineProps<{ supplier: PurchaseOrderSupplier }>();
const emit = defineEmits<{ (e: "selectedSupplier", value: Supplier): void }>();

const supplierTableRef = ref();

const focusFirstSearchResult = (event: Event) => {
  event.stopPropagation();
  supplierTableRef.value.$el.querySelector("tbody tr:first-of-type").focus();
};

const searchInputEnterKey = async (event: Event) => {
  event.stopPropagation();

  if (suppliers.value.length === 1) {
    const result = await getSupplier(suppliers.value[0].id);
    if (!result) return;

    emit("selectedSupplier", result);
    return;
  }

  if (suppliers.value.length > 0) {
    focusFirstSearchResult(event);
    return;
  }
};

const onRowSelect = async (event: DataTableRowSelectEvent) => {
  const searchSupplier = event.data as SearchSupplier;
  if (!searchSupplier) return;

  const result = await getSupplier(searchSupplier.id);
  if (!result) return;

  emit("selectedSupplier", result);
};

const selectAllOnFocus = (event: FocusEvent) => {
  (event.target as HTMLInputElement).select();
};

const searchInputRef = ref();
nextTick(() => {
  searchInputRef.value.$el.focus();
});

const loading = ref(false);
const totalHits = ref(0);
const query = ref<string>("");

const suppliers = ref<SearchSupplier[]>([]);
const search = async (q: string) => {
  try {
    loading.value = true;
    const request = new SupplierSearchRequest(q.length < 1 ? "*" : q);
    const response = await supplierSearch(request);

    suppliers.value = response.suppliers;
    totalHits.value = response.totalHits;
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  query.value = props.supplier?.name ?? "";
  await search(query.value);
});
</script>

<style lang="scss">
.c-supplier-panel {
  .c-no-search-result {
    color: var(--pink-700);
  }

  .p-datatable-header {
    padding: 0;
    margin-bottom: 0.5rem;

    .p-inputtext {
      border: none;
      border-bottom: var(--floating-input-border-on-focus);
      border-radius: 0;

      &:focus {
        box-shadow: none;
        border-color: var(--active-component-color);
      }
    }
    .p-input-icon-left > i:first-of-type {
      color: var(--active-component-color);
      font-weight: bolder;
    }
  }

  .p-datatable-footer {
    padding: 0.75rem 0;
    float: right;
  }
}
</style>
