import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";
import { ComputedRef, ref } from "vue";
import { PurchaseOrderLine } from "@/models/purchase-order/PurchaseOrderLine";

export function usePurchaseOrderLinesFunctions(
  selectedRowIndex: { value: number },
  resetRowValues: (index: number) => void,
  setFocusedRow: (index: number) => void,
  openProductInfoDialog: (productId: string) => void,
  onConfirmDelete: (event: KeyboardEvent, orderLine: PurchaseOrderLine) => void,
  purchaseOrderLinesComputed: ComputedRef<PurchaseOrderLine[]>,
  purchaseOrderStatus: PurchaseOrderStatus
) {
  const tablePurchaseOrderLines = ref();
  const toast = useCumulusToast(useToast());

  const handleKeyPress = (event: KeyboardEvent) => {
    let isRowFocused = false;

    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      const focusedElement = document.activeElement;
      const rows = tablePurchaseOrderLines.value?.$el.querySelectorAll("tbody tr");
      if (rows) {
        rows.forEach((row: HTMLTableRowElement, index: number) => {
          if (row === focusedElement) {
            selectedRowIndex.value = index;
            setFocusedRow(index);
          }
        });
      }
    }

    if (event.key === "Escape") {
      const focusedElement = document.activeElement;
      const rows = tablePurchaseOrderLines.value?.$el.querySelectorAll("tbody tr");

      if (rows) {
        rows.forEach((row: HTMLTableRowElement, index: number) => {
          if (row === focusedElement) {
            const containsInput = row.querySelector("input:not([type='checkbox'])") !== null;
            if (!containsInput) {
              selectedRowIndex.value = index;
              setFocusedRow(index);
              resetRowValues(index);
            }
          }
        });
      }
      event.stopPropagation();
    }

    const orderLine = purchaseOrderLinesComputed.value[selectedRowIndex.value];
    if (event.key.toLowerCase() === "p") {
      if (orderLine) {
        openProductInfoDialog(orderLine.product.id);
      }
    } else if (event.key === "Delete" && purchaseOrderStatus === PurchaseOrderStatus.Open) {
      const focusedElement = document.activeElement;
      const rows = tablePurchaseOrderLines.value?.$el.querySelectorAll("tbody tr");
      if (rows) {
        rows.forEach((row: HTMLTableRowElement) => {
          if (row === focusedElement && orderLine) {
            onConfirmDelete(event, orderLine);
            isRowFocused = true;
          }
        });
      }
    }
    if (
      !isRowFocused &&
      (event.key.toLowerCase() === "p" || event.key === "Delete") &&
      purchaseOrderStatus === PurchaseOrderStatus.Open
    ) {
      toast.add({
        severity: "warn",
        life: 2000,
        detail: "select a row to perform this action",
      });
    }
  };

  return {
    handleKeyPress,
    tablePurchaseOrderLines,
  };
}
