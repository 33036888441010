import { useAuth } from "@cumulus/event-bus";
import { Employee } from "../../models/employee/Employee";
import { employeeApi } from "./EmployeeApi";
import { useErrorHandler } from "../ErrorHandler";

export function useEmployee() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getEmployee = async (): Promise<Employee> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await employeeApi.getCurrentEmployee(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { getEmployee };
}
