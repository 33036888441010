<template>
  <div class="grid grid-cols-12 gap-4">
    <div class="col-span-12 md:col-span-6 lg:col-span-6">
      <h4>{{ t("purchase.search.latest-purchase-orders") }}</h4>
      <DataTable dataKey="purchaseOrderLineId" :value="latestPurchaseOrders" :loading="loading" class="c-datatable">
        <Column field="registered" :header="t('purchase.search.registered')" :body="d">
          <template #body="{ data, field, index }">
            <span :data-testid="`purchase-latest-order-line-registered-date-${index}`">{{ d(data[field]) }}</span>
          </template>
        </Column>
        <Column field="purchaseOrderNumber" :header="t('purchase.search.po-number')">
          <template #body="{ data, field, index }">
            <span
              @click="goToPurchaseOrder(data.purchaseOrderId)"
              class="c-purchase-order-link"
              :data-testid="`purchase-latest-order-line-po-number-${index}`"
            >
              {{ data[field] }}
            </span>
          </template>
        </Column>
        <Column field="supplierName" :header="t('purchase.search.supplier')">
          <template #body="{ data, field, index }">
            <span :data-testid="`purchase-latest-order-line-supplier-name-${index}`">{{ data[field] }}</span>
          </template>
        </Column>
        <Column field="quantity" :header="t('purchase.search.quantity')" class="text-right">
          <template #body="{ data, field, index }">
            <span :data-testid="`purchase-latest-order-line-quantity-${index}`">{{ n(data[field]) }}</span>
          </template>
        </Column>
        <Column field="purchasePrice" :header="t('purchase.search.cost')" class="text-right">
          <template #body="{ data, field, index }">
            <span :data-testid="`purchase-latest-order-line-cost-${index}`">{{ n(data[field], "decimal") }}</span>
          </template>
        </Column>
        <Column field="currencyIso" :header="t('purchase.search.currency')" class="text-right">
          <template #body="{ data, field, index }">
            <span :data-testid="`purchase-latest-order-line-currency-${index}`">{{ data[field] }}</span>
          </template>
        </Column>

        <template #empty>
          {{ t("purchase.search.no-po-found") }}
        </template>
      </DataTable>
    </div>
    <div class="col-span-12 md:col-span-6 lg:col-span-6">
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 md:col-span-5">
          <h4>{{ t("purchase.search.supplier-product-number") }}</h4>
          <span class="w-full flex" data-testid="product-info-extended-supplier-product-number">{{
            selectedProduct.supplierProductNumber
          }}</span>
        </div>
        <div class="col-span-12 md:col-span-3">
          <h4>{{ t("purchase.search.ean") }}</h4>
          <span class="w-full flex" data-testid="product-info-extended-gtin">{{ selectedProduct.gtin }}</span>
        </div>
        <div class="col-span-12 md:col-span-2">
          <h4>{{ t("purchase.search.list-price") }}</h4>
          <span class="w-full flex" data-testid="product-info-extended-list-price-ex-vat">{{
            n(selectedProduct.listPrice, "currency", selectedProduct.priceCurrencyIso)
          }}</span>
        </div>
        <div class="col-span-12 md:col-span-2">
          <h4>{{ t("purchase.search.contribution-margin") }}</h4>
          <span class="w-full flex" data-testid="product-info-extended-list-contribution-margin">{{
            calculateContributionMargin(selectedProduct)
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Currency } from "@/models/currency/Currency";
import { PurchaseOrderLineInfo } from "@/models/purchase-order/PurchaseOrderLineInfo";
import { SearchProductForPurchaseOrder } from "@/models/search/product/SearchProductForPurchaseOrder";
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { usePurchaseOrderService } from "@/api/purchase-order/PurchaseOrderService";

const props = defineProps<{
  selectedProduct: SearchProductForPurchaseOrder;
  currencies: Currency[];
}>();

const { n, d, t } = useI18n();
const router = useRouter();
const { getLatestOrdersForProduct } = usePurchaseOrderService();
const latestPurchaseOrders = ref<PurchaseOrderLineInfo[]>([]);
const loading = ref<boolean>(false);

const goToPurchaseOrder = (purchaseOrderId: string) => {
  if (purchaseOrderId == null) return;
  const route = router.resolve({ name: "purchase-edit", params: { id: purchaseOrderId } });
  window.open(route.href, "_blank");
};

const getPurchaseOrdersForProduct = async (productId: string) => {
  try {
    loading.value = true;
    latestPurchaseOrders.value = await getLatestOrdersForProduct(productId);
  } finally {
    loading.value = false;
  }
};

const calculateContributionMargin = (product: SearchProductForPurchaseOrder) => {
  if (product.listPrice == null || product.supplierPurchasePrice == null || product.listPrice <= 0) return "";

  let priceToFormat = product.supplierPurchasePrice;

  if (product.priceCurrencyIso !== product.supplierCurrencyIso) {
    const currency = props.currencies.find((c) => c.currencyIso === product.supplierCurrencyIso);
    if (currency == null) return "";
    priceToFormat = (product.supplierPurchasePrice * currency.buyRate) / currency.unit;
  }

  const contributionMargin = Math.min(((product.listPrice - priceToFormat) / product.listPrice) * 100, 100);
  return n(contributionMargin, "decimal");
};

watch(
  () => props.selectedProduct,
  (newVal, oldVal) => {
    if (newVal.id !== oldVal.id) {
      getPurchaseOrdersForProduct(newVal.id);
    }
  },
  { deep: true }
);

onMounted(() => {
  getPurchaseOrdersForProduct(props.selectedProduct.id);
});
</script>

<style scoped lang="scss">
.c-purchase-order-link {
  cursor: pointer;
  color: var(--primary-color);
}
.c-purchase-order-link:hover {
  text-decoration: underline;
}
</style>
