import { httpClient, AuthHeaders } from "@cumulus/http";
import { Warehouse } from "../../models/warehouse/Warehouse";

class WarehouseApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_WAREHOUSE != "undefined" && import.meta.env.VITE_APP_API_WAREHOUSE
        ? `${import.meta.env.VITE_APP_API_WAREHOUSE}/warehouses`
        : `${import.meta.env.VITE_APP_API_URL as string}/warehouses`;
  }

  public async get(authHeaders: AuthHeaders, id: string): Promise<Warehouse> {
    return await httpClient(this.uri, authHeaders)
      .get(id)
      .then(({ data }) => data);
  }

  public async getAll(authHeaders: AuthHeaders): Promise<Warehouse[]> {
    return await httpClient(this.uri, authHeaders)
      .get("")
      .then(({ data }) => data);
  }
}
const warehouseApi = new WarehouseApi();
export { warehouseApi };
