import { PurchaseOrderSearchFilters } from "./PurchaseOrderSearchFilters";

export class PurchaseOrderSearchRequest {
  query = "";
  page = 1;
  pageSize = 25;
  sortBy = "";
  sortOrder = "asc";
  filters = new PurchaseOrderSearchFilters();

  constructor(query: string) {
    this.query = query;
  }
}
