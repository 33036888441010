<template>
  <FloatLabel variant="on">
    <InputText
      v-model="comment"
      ref="commentInputRef"
      id="purchase-comment"
      data-testId="purchase-comment"
      maxlength="8000"
      :disabled="!allowEdit"
      :fluid="true"
      :class="{ 'p-disabled': !allowEdit }"
      @keydown="handleKeydown"
      @click="showCommentPanel"
    />
    <label for="purchase-comment">{{ t("purchase.comment.label") }}</label>
  </FloatLabel>
  <CommentPanel ref="commentPanelRef" v-model:comment="comment" />
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { PurchaseOrderStatus } from "@/models/purchase-order/PurchaseOrderStatus";
import CommentPanel from "./CommentPanel.vue";

const { t } = useI18n();

const props = defineProps<{
  purchaseOrderStatus?: PurchaseOrderStatus;
  inGoodsReception: boolean;
}>();

const comment = defineModel<string>("comment");
const allowEdit = computed<boolean>(() => {
  if (props.inGoodsReception) return false;
  return (
    !props.purchaseOrderStatus ||
    props.purchaseOrderStatus === PurchaseOrderStatus.Open ||
    props.purchaseOrderStatus === PurchaseOrderStatus.PartiallyReceived
  );
});

const commentPanelRef = ref();
const showCommentPanel = (event: Event) => {
  const key = (event as KeyboardEvent).key;
  if (key === "Shift" || key === "Escape" || key === "Tab") {
    return;
  }

  commentPanelRef.value.toggle(event);
};

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Enter") {
    commentPanelRef.value.toggle(event);
  }
};
</script>
